import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    gray_row: {
      color: theme.palette.dark.gray,
      backgroundColor: theme.palette.miscellaneous.grayDivider,
      borderRadius: theme.spacing(0.2),
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        0.5
      )}px ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    white_row: {
      color: theme.palette.dark.gray,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        0.5
      )}px ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    town_name_container: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 1,
    },
    state_name_container: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    town_price_container: {
      width: "10%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "right",
      color: palette.grayVariation.grayForSubLabel,
    },
    is_selected_container: {
      width: "10%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    checkbox: {
      marginRight: "0px !important",
      marginLeft: "0px !important",
      "& > .MuiCheckbox-root": {
        padding: 0,
      },
      cursor: "pointer",
    },
    date_row: {
      marginLeft: `${theme.spacing(1.2)}px`,
      padding: `0px ${theme.spacing(4)}px 0px ${theme.spacing(3.2)}px`,
      display: "flex",
      flexDirection: "column",
    },
    date_title: {
      fontSize: theme.spacing(1.5),
      color: palette.grayVariation.grayForSubLabel,
    },
    date_info_row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    date_letter: {
      fontSize: theme.spacing(2),
      color: palette.grayVariation.grayForSubLabel,
    },
    actions_container: {
      display: "flex",
      alignItems: "center",
    },

    delete_icon: {
      cursor: "pointer",
      width: theme.spacing(3),
      height: theme.spacing(2),
    },
  };
});

export { useStyles };
