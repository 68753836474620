import {
  ITEMS_COUNT,
  SELECTED_TOWNS,
  ORGANIZATION_TYPE,
  POST_ON_FACEBOOK,
  AD_FREE,
  INCLUDE_IN_ENEWS,
  ENEWS_DATES_ARRAY,
} from "../components/Diy/diyConstants";
import cache from "./cache";

const updateSummaryComponentListener = (setTownsSelected) => {
  window.addEventListener("updateSummaryComponent", () => {
    setTownsSelected(cache.get(SELECTED_TOWNS));
  });
};

const showOrHideFacebookBlockComponentListener = (setShowFacebookBlock) => {
  window.addEventListener("showOrHideFacebookBlockComponent", () => {
    if (cache.get(ORGANIZATION_TYPE) == "political_candidates") {
      setShowFacebookBlock(false);
    } else {
      setShowFacebookBlock(true);
    }
  });
};

const removeTownListener = (removeTown) => {
  window.addEventListener("removeTown", (event) => removeTown(event));
};

const removeFacebookTownListener = (removeFacebookTown) => {
  window.addEventListener("removeFacebookTown", (event) =>
    removeFacebookTown(event)
  );
};

const updateFacebookComponentListener = (setTownsSelected) => {
  window.addEventListener("updateFacebookComponent", (event) => {
    setTownsSelected(event.detail.facebookTowns);
  });
};

const removeAllFacebookTownsRemovalComponentListener = (
  removeAllFacebookTowns
) => {
  window.addEventListener("removeAllFacebookTown", () => {
    removeAllFacebookTowns([]);
  });
};
const handleTownRemoval = (town) => {
  const removeTownEvent = new CustomEvent("removeTown", {
    bubbles: true,
    detail: { town },
  });
  window.dispatchEvent(removeTownEvent);
};

const handleFacebookTownRemoval = (town) => {
  const removeFacebookTownEvent = new CustomEvent("removeFacebookTown", {
    bubbles: true,
    detail: { town },
  });
  window.dispatchEvent(removeFacebookTownEvent);
};

const setFormErrors = (error) => {
  const formErrorEvent = new CustomEvent("formErrorComponent", {
    bubbles: true,
    detail: { error },
  });
  window.dispatchEvent(formErrorEvent);
};

const formErrorsListener = (setErrors) => {
  window.addEventListener("formErrorComponent", (event) => {
    setErrors(event?.detail?.error);
  });
};

const handleOpenMobileShoppingCart = () => {
  const openMobileShoppingCart = new CustomEvent("openMobileShoppingCart", {
    bubbles: true,
    detail: { state: true },
  });
  window.dispatchEvent(openMobileShoppingCart);
};

const setOpenMobileShoppingCart = (setOpenMobileCart) => {
  window.addEventListener("openMobileShoppingCart", (event) =>
    setOpenMobileCart(event.detail.state)
  );
};

const updateItemCountListener = (setItemCount) => {
  window.addEventListener("updateItemCountComponent", () => {
    setItemCount(cache.get(ITEMS_COUNT));
  });
};

// This runs second when clicking Facebook checkbox
const updateFacebookOptionListener = (setFacebookCheck) => {
  window.addEventListener("updateFacebookOptionComponent", () => {
    setFacebookCheck(cache.get(POST_ON_FACEBOOK) === 1);
  });
};

const updateAdsFreeOptionListener = (setAdsFreeCheck) => {
  window.addEventListener("updateAdsFreeOptionComponent", () => {
    setAdsFreeCheck(cache.get(AD_FREE) === 1);
  });
};

const updateIncludeInEnewsListener = (setEnews) => {
  window.addEventListener("updateEnewsOptionComponent", () => {
    setEnews(cache.get(INCLUDE_IN_ENEWS) === 1);
  });
};

const updateEnewsDatesListener = (setDates) => {
  window.addEventListener("updateEnewsDates", () => {
    setDates(cache.get(ENEWS_DATES_ARRAY));
  });
};

export {
  updateSummaryComponentListener,
  removeTownListener,
  removeFacebookTownListener,
  updateFacebookComponentListener,
  removeAllFacebookTownsRemovalComponentListener,
  handleTownRemoval,
  handleFacebookTownRemoval,
  setFormErrors,
  formErrorsListener,
  handleOpenMobileShoppingCart,
  setOpenMobileShoppingCart,
  updateItemCountListener,
  showOrHideFacebookBlockComponentListener,
  updateFacebookOptionListener,
  updateAdsFreeOptionListener,
  updateIncludeInEnewsListener,
  updateEnewsDatesListener,
};
