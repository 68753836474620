import ReactOnRails from "react-on-rails";
import ArticleHeader from "../src/components/Article/ArticleHeader";
import Sponsor from "../src/components/Sponsor/Sponsor";
import MastheadLayout from "../src/components/Masterhead/Layouts/Layouts";
import DiyUpperSection from "../src/components/Diy/DiyUpperSection";
import ShoppingSummary from "../src/components/Diy/ShoppingSummary/ShoppingSummary";
import Typography from "../src/common/Typography/Typography";
import BottomActions from "../src/components/Diy/BottomActions/BottomActions";
import SubmitContentModal from "../src/components/Modals/SubmitContentModal/SubmitContentModal";
import EventsList from "../src/pages/Events/EventsList/EventsList";
import Event from "../src/pages/Events/Event/Event";
import { initializeGA } from "../src/utils/googleAnalytics";
import Breadcrumbs from "../src/common/Breadcrumbs/Breadcrumbs";
import Ad from "../src/components/Ad/Ad";
import VideoAd from "../src/components/Ad/VideoAd";
import ObituariesList from "../src/pages/Obituaries/ObituariesList/ObituariesList";
import Obituary from "../src/pages/Obituaries/Obituary/Obituary";
import RequestObituaryRole from "../src/components/Modals/RequestObituaryRole/RequestObituaryRole";
import Edit from "../src/components/Actions/Edit";
import Error from "../src/pages/Error/Error";
import UserShowPage from "../src/pages/Users/UserShowPage/UserShowPage";
import ColumnsIndex from "../src/pages/Columns/Index/ColumnsIndex";
import ColumnArticlesList from "../src/pages/Columns/ArticlesList/ColumnArticlesList";
import ClassifiedsIndex from "../src/pages/Classifieds/Index/ClassifiedsIndex";
import ClassifiedShow from "../src/pages/Classifieds/Show/ClassifiedShow";
import ExitIntentPopup from "../src/components/ExitIntentPopup/ExitIntentPopup";
import StickySocialNetworkShareButtons from "../src/components/StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import SocialNetworkShareButtons from "../src/components/SocialNetworkShareButtons/SocialNetworkShareButtons";
import RealEstatesIndex from "../src/pages/RealEstateListings/Index/Index";
import RealEstateShowPage from "../src/pages/RealEstateListings/Show/Show";
import Footer from "../src/components/Footer/Footer";
import SubscriptionBox from "../src/components/SubscriptionBox/SubscriptionBox";
import ResetPassword from "../src/pages/Users/ResetPassword/ResetPassword";
import ThankYou from "../src/components/ThankYou/ThankYou";
import Pages from "../src/pages/Pages/Pages";
import GamesLayout from "../src/Layouts/Games/GamesLayout";
import GamesCard from "../src/components/Games/GamesCard/GamesCard";
import BrowserValidator from "../src/components/BrowserValidator/BrowserValidator";
import Subsection from "../src/pages/Subsection/Subsection";
import DocumentsManagement from "../src/pages/CMS/Content/DocumentsManagement/DocumentsManagement";
import CheckboxOptions from "../src/components/Diy/FormFields/LegalNoticeFields/CheckboxOptions/CheckboxOptions";

initializeGA();

ReactOnRails.register({
  ArticleHeader,
  Sponsor,
  MastheadLayout,
  DiyUpperSection,
  CheckboxOptions,
  ShoppingSummary,
  Typography,
  BottomActions,
  SubmitContentModal,
  EventsList,
  Event,
  Breadcrumbs,
  Ad,
  VideoAd,
  ObituariesList,
  Obituary,
  RequestObituaryRole,
  Edit,
  Error,
  UserShowPage,
  ColumnsIndex,
  ColumnArticlesList,
  ClassifiedsIndex,
  ClassifiedShow,
  ExitIntentPopup,
  StickySocialNetworkShareButtons,
  SocialNetworkShareButtons,
  RealEstatesIndex,
  RealEstateShowPage,
  Footer,
  SubscriptionBox,
  ResetPassword,
  ThankYou,
  Pages,
  GamesLayout,
  GamesCard,
  BrowserValidator,
  Subsection,
  DocumentsManagement,
  Subsection,
});
