import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ObituariesHeader from "../../../components/Obituary/ObituariesHeader/ObituariesHeader";
import MainObituary from "../../../components/Obituary/MainObituary/MainObituary";
import ObituaryBlock from "../../../components/Obituary/ObituaryBlock/ObituaryBlock";
import { useStyles } from "./ObituariesList.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../../common/Pagination/Pagination";
import { handleSearch } from "../../../services/obituaries";
import { getNamesForDropdownSearch } from "../../../services/obituaries";
import Sponsor from "../../../components/Sponsor/Sponsor";
import Button from "../../../common/Button/Button";
import { redirectToPage } from "../../../utils/helper";
import withConfig from "../../../utils/withConfig";

const PER_PAGE = 10;

const ObituariesList = ({ town_id, town_slug, sponsor_info }) => {
  const [obituariesData, setObituariesData] = useState([]);
  const [mainObituary, setMainObituary] = useState();
  const [availableNames, setAvailableNames] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  let [page, setPage] = useState(1);
  const classes = useStyles();
  const count = Math.ceil(obituariesData.length / PER_PAGE);
  const items = usePagination(obituariesData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    items.jump(p);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getNamesForDropdownSearch(town_id, null, setAvailableNames);
  }, []);

  const printSponsor = () => {
    if (sponsor_info !== null) {
      return <Sponsor sponsor_info={sponsor_info} />;
    }
  };

  return (
    <>
      {printSponsor()}
      <ObituariesHeader
        availableNames={availableNames}
        townId={town_id}
        townSlug={town_slug}
        onSubmitSearch={handleSearch}
        setObituariesData={setObituariesData}
        setMainObituary={setMainObituary}
        setIsSearch={setIsSearch}
      />

      {mainObituary && !isSearch && (
        <MainObituary mainObituary={mainObituary} />
      )}

      {obituariesData.length > 0 && (
        <>
          <div className={classes.all_obituaries}>
            <Typography
              level="h3_sub_header"
              color={isSearch ? "black" : "gray"}
              bold
            >
              {isSearch ? labels.SEARCH_RESULTS : labels.ALL_OBITUARIES}
            </Typography>
          </div>

          <div className={classes.obituaries_list}>
            {items.currentData().map((obituary, id) => {
              return (
                <Fragment key={id}>
                  <ObituaryBlock obituary={obituary} />
                </Fragment>
              );
            })}
          </div>
        </>
      )}

      {((isSearch && obituariesData.length === 0) ||
        obituariesData.length === 0) && (
        <div>
          <Typography level="h3_sub_header" color="black" bold>
            {labels.SEARCH_RESULTS}
          </Typography>
          <div className={classes.no_search_results}>
            <div>
              <Typography level="paragraph_2" color="gray">
                {labels.NO_OBITUARIES}
              </Typography>
              <br />
              <Typography level="paragraph_2" color="gray">
                {labels.SUBMIT_AN_OBITUARY_BELOW}
              </Typography>
            </div>
            <Button
              className={classes.obituary_list_button}
              classes={{ label: classes.button_label }}
              level="simple_button"
              color="primary"
              onClick={() =>
                redirectToPage(withConfig("OBITUARY_NEW_DIY"), "_top")
              }
              children={labels.SUBMIT_AN_OBITUARY}
            />
          </div>
        </div>
      )}

      {obituariesData.length > PER_PAGE && (
        <div className={classes.pagination_container}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            getItemAriaLabel={(type) => {
              console.log({ type });
            }}
            className={classes.obituaries_pagination}
          />
        </div>
      )}
    </>
  );
};

ObituariesList.propTypes = {
  town_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  town_slug: PropTypes.string,
  sponsor_info: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ObituariesList;
