import React, { useEffect, useState } from "react";
import { array, number, object, oneOfType, string } from "prop-types";
import labels from "../../../../config/labels";
import { useStyles } from "./LegalNoticeFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import Typography from "../../../../common/Typography/Typography";
import RadioButtonGroup from "../../../../common/RadioButtonGroup/RadioButtonGroup";
import ScheduledDate from "./ScheduledDate/ScheduledDate";
import {
  LEGAL_ENTITY_ID,
  SCHEDULED_DATES,
  LEGAL_NOTICE_NAME,
  MANDATORY_FIELDS_FOR_LEGAL_NOTICE,
  SCHEDULED_HOUR,
  CONTENT_ID,
} from "../../diyConstants";
import TextField from "../../../../common/TextField/TextField";
import LegalNoticeEntityInputForm from "./LegalNoticeEntityInputForm/LegalNoticeEntityInputForm";
import {
  useLegalNoticeEntities,
  withLegalNoticeEntitiesProvider,
} from "../../../../context/LegalNoticeEntities";

const DATE = new Date();
DATE.setMinutes(0);
DATE.setSeconds(0);

const LegalNoticeFields = ({ formErrors, content, userId, usStates }) => {
  const classes = useStyles();
  const {
    userIdForEntities,
    setUserIdForEntities,
    fetchLegalNoticesForUser,
    setSelectedLegalEntityId,
    selectedLegalEntityId,
    legalNoticesEntities,
    setUsStatesForDropdown,
  } = useLegalNoticeEntities();
  const [dates, setDates] = useState(
    content?.scheduled_dates?.length > 0
      ? content.scheduled_dates.map((d) => new Date(d))
      : [DATE]
  );

  const [times, setTimes] = useState(
    content?.scheduled_hour?.length > 0
      ? content.scheduled_hour.map((d) => d)
      : [DATE]
  );
  const [legalNoticeName, setLegalNoticeName] = useState(
    content?.name ? content.name : ""
  );
  const { setProperty } = useSubmitableContentForm();

  useEffect(() => {
    setUserIdForEntities(userId);
    setSelectedLegalEntityId(
      content?.legal_notice_entity_id === ""
        ? content.legal_notice_entity_id
        : ""
    );
    setUsStatesForDropdown(usStates);

    return () => {
      setUserIdForEntities(null);
      setSelectedLegalEntityId("");
      setUsStatesForDropdown([]);
    };
  }, []);

  useEffect(() => {
    if (userIdForEntities) {
      fetchLegalNoticesForUser();
    }
  }, [userIdForEntities]);

  useEffect(() => {
    setProperty(LEGAL_ENTITY_ID, selectedLegalEntityId);
    setProperty(SCHEDULED_DATES, dates);
    setProperty(SCHEDULED_HOUR, times);
    setProperty(LEGAL_NOTICE_NAME, legalNoticeName);
    setProperty(CONTENT_ID, content?.id);
  }, [selectedLegalEntityId, dates, times, legalNoticeName, content]);

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_LEGAL_NOTICE.find(
      (mandatory) => mandatory.key === prop
    );

    if (formErrors?.length > 0 && formErrors.includes(validation.error)) {
      return true;
    }
  };

  const titleize = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const printItem = (item) => {
    const { value, key } = item;
    return value && key !== "id" && key !== "logo";
  };

  const radioButtonItems = () => {
    return legalNoticesEntities?.map((item) => {
      return {
        id: item.id,
        label: (
          <div className={classes.legal_entity_container}>
            <div className={classes.legal_entity}>
              {Object.entries(item).map(
                ([key, value]) =>
                  printItem({ key, value }) && (
                    <Typography level="paragraph_2">
                      <strong>{titleize(key)}</strong>: {value}
                    </Typography>
                  )
              )}
            </div>
            {item.logo && (
              <img src={item.logo} alt="logo" className={classes.logo} />
            )}
          </div>
        ),
      };
    });
  };

  return (
    <>
      {legalNoticesEntities?.length == 0 && (
        <Typography level="h4_sub_header_4" color="gray" bold>
          {labels.CREATE_A_LEGAL_NOTICE_ENTITY}
        </Typography>
      )}
      {legalNoticesEntities?.length > 0 && (
        <RadioButtonGroup
          groupLabel={
            <Typography level="h4_sub_header_4" color="gray" bold>
              {labels.CHOOSE_A_LEGAL_NOTICE_ENTITY}
            </Typography>
          }
          items={radioButtonItems()}
          radioGroupProps={{
            row: true,
            value: selectedLegalEntityId,
            onChange: (e) => {
              setSelectedLegalEntityId(Number(e.target.value));
            },
            classes: { root: classes.form_group },
          }}
          classes={{
            radio_button: { checked: classes.checked_radio_button },
            group_label: { root: classes.group_label },
            form_control: { root: classes.form_control },
            form_control_label: {
              label: classes.data_box,
              root: classes.data_box_container,
            },
          }}
        />
      )}
      <LegalNoticeEntityInputForm />
      <TextField
        label={labels.LEGAL_NOTICE_NAME}
        onChange={(e) => setLegalNoticeName(e.target.value)}
        defaultValue={legalNoticeName}
        labelLevel="h4_sub_header_4"
        required
        error={shouldMarkError(LEGAL_NOTICE_NAME)}
        className={classes.legal_entity_headline}
      />
      <Typography level="h4_sub_header_4" color="gray" bold>
        {labels.SCHEDULE_PUBLICATION_DATE}
      </Typography>
      <ScheduledDate
        dates={dates}
        setDates={setDates}
        times={times}
        setTimes={setTimes}
      />
    </>
  );
};

LegalNoticeFields.propTypes = {
  content: object,
  userId: oneOfType([string, number]),
  formErrors: array,
};

export default withLegalNoticeEntitiesProvider(LegalNoticeFields);
