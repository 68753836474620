import React, { useEffect, useState } from "react";
import { useStyles } from "./AdsFreeShoppingListRow.style";
import PropTypes from "prop-types";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";
import { LETTERS_TO_THE_EDITOR } from "../../diyConstants";
import { useDiy, withDiyContextProvider } from "../../../../context/DiyContext";
import Checkbox from "../../../../common/Checkbox/Checkbox";

const AdsFreeShoppingListRow = ({ town, formType, itemQuantity }) => {
  const classes = useStyles();
  const { updateAdsFreeTowns } = useDiy();
  const [isSelected, setIsSelected] = useState(town?.ads_free_selected);

  const getTownPriceAccordingToFormType = (item) => {
    if (!isSelected) {
      return 0;
    }
    if (formType !== LETTERS_TO_THE_EDITOR) {
      return item.ads_free_price;
    } else {
      return 0;
    }
  };

  const getTownPriceAccordingToFormTypeWithQuantity = (item, quantity) => {
    if (!isSelected) {
      return 0;
    }
    if (formType !== LETTERS_TO_THE_EDITOR) {
      return item.ads_free_price * quantity;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    updateAdsFreeTowns(town, isSelected);
  }, [isSelected]);

  return (
    <div className={classes.white_row}>
      <div className={classes.town_name_container}>
        <Typography level="t1_text_1">
          {town.label}
          {itemQuantity ? ` (x ${itemQuantity})` : ""}
        </Typography>
      </div>
      <div className={classes.state_name_container}>
        <Typography level="t1_text_1">{town.state[1]}</Typography>
      </div>
      <div className={classes.town_price_container}>
        <Typography level="t1_text_1">
          {labels.DOLLAR_SIGN}
          {itemQuantity
            ? getTownPriceAccordingToFormTypeWithQuantity(town, itemQuantity)
            : getTownPriceAccordingToFormType(town)}
        </Typography>
      </div>
      <Checkbox
        className={classes.checkbox}
        onChange={() => setIsSelected(!isSelected)}
        checkboxColor="#000000"
        checked={isSelected}
        dataAttribute="town-ads-free"
      />
    </div>
  );
};

AdsFreeShoppingListRow.propTypes = {
  town: PropTypes.shape({
    label: PropTypes.string,
    price: PropTypes.number,
    state: PropTypes.arrayOf(PropTypes.string),
  }),
  formType: PropTypes.string,
  isFacebook: PropTypes.bool,
  itemQuantity: PropTypes.number,
};

export default withDiyContextProvider(AdsFreeShoppingListRow);
