import { makeStyles } from "../../../common/Theme/Theme";
import values from "../../../common/Theme/themes/breakpoints/values";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    container: {
      justifyContent: `center !important`,
      width: "100%",
      backgroundColor: `${palette.grayVariation.grayForBackground} !important`,
      borderRadius: `${theme.spacing(1)}px !important`,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        gap: theme.spacing(2),
      },
    },
    title: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
      fontFamily: theme.fontFamily.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.spacing(2)}px !important`,
      },
    },
    optionRow: {
      display: "flex",
      alignItems: "center",
      gridTemplateColumns: "40px auto 120px",
      padding: `${theme.spacing(2)}px 0`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    checkboxWrapper: {
      display: "flex",
      alignItems: "center",
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(1.2),
    },
    optionTitle: {
      fontSize: theme.spacing(2),
      fontFamily: theme.fontFamily.primary,
    },
    add_more_button: {
      color: `${palette.light.orange3} !important`,
      cursor: "pointer",
      display: "inline-block",
      "&.MuiButton-root": {
        fontSize: `${theme.spacing(1.75)}px !important`,
        fontFamily: theme.fontFamily.primary,
        textAlign: "center",
        textDecoration: "underline",
        textDecorationColor: palette.light.orange3,
        textTransform: "capitalize",
      },
    },
    optionDescription: {
      fontSize: `${theme.spacing(1.75)}px`,
      color: palette.grayVariation.grayForSubLabel,
      fontFamily: theme.fontFamily.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.spacing(1.5)}px !important`,
      },
    },
    priceWrapper: {
      textAlign: "right",
      display: "grid",
    },
    optionPrice: {
      textAlign: "center",
    },
    priceDetails: {
      fontSize: theme.spacing(1.5),
      color: palette.grayVariation.grayForSubLabel,
      fontFamily: theme.fontFamily.primary,
      fontSize: theme.spacing(1),
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.spacing(1)}px !important`,
      },
    },
    pickers: {
      width: "100%",
      "&.MuiFormControl-marginNormal": {
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(0.8),
        [theme.breakpoints.down(900)]: {
          marginRight: 0,
        },
      },
      "& div.MuiInputBase-root": {
        width: "100%",
        "& input.MuiInputBase-input": {
          padding: 0,
          paddingLeft: theme.spacing(0.75),
        },
      },
    },
    delete_icon: {
      width: theme.spacing(3),
      height: theme.spacing(2),
    },
    container_add_button: {
      textAlign: "start",
      marginLeft: `${theme.spacing(1.8)}px !important`,
      padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px ${theme.spacing(5)}px  !important`,
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px ${theme.spacing(
          1
        )}px ${theme.spacing(3)}px  !important`,
      },
    },
    delete_container: {
      marginTop: `${theme.spacing(0)}px !important`,
    },
    date_container: {
      marginLeft: `${theme.spacing(6)}px !important`,
      [theme.breakpoints.down("sm")]: {
        marginLeft: `${theme.spacing(3.3)}px !important`,
      },
      padding: `${theme.spacing(0.4)}px ${theme.spacing(1)}px ${theme.spacing(
        0
      )}px !important`,
      [theme.breakpoints.down(values.sm)]: {
        marginLeft: `${theme.spacing(3.3)}px !important`,
        width: "calc(100% + 130px) !important",
      },
    },
    facebook_excerpt: {
      marginLeft: `${theme.spacing(6)}px !important`,
      [theme.breakpoints.down("sm")]: {
        marginLeft: `${theme.spacing(3.3)}px !important`,
      },
      flex: 1,
      padding: theme.spacing(1),
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
      },
    },
    excerpt: {
      padding: theme.spacing(1),
      width: "100%",
    },
    excerpt_header: {
      marginTop: theme.spacing(1),
    },
    facebook_url: {
      backgroundColor: "transparent !important",
      color: "black !important",
      "& :focus": {
        backgroundColor: "transparent !important",
      },
    },
    facebook_excerpt: {
      marginLeft: `${theme.spacing(6)}px !important`,
      [theme.breakpoints.down("sm")]: {
        marginLeft: `${theme.spacing(3.3)}px !important`,
      },
      flex: 1,
      padding: theme.spacing(1),
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
      },
    },
    excerpt: {
      padding: theme.spacing(1),
      width: "100%",
    },
    excerpt_header: {
      marginTop: theme.spacing(1),
    },
    facebook_url: {
      backgroundColor: "transparent !important",
      color: "black !important",
      "& :focus": {
        backgroundColor: "transparent !important",
      },
    },
  };
});

export { useStyles };
