import React, { Fragment } from "react";
import PropTypes, { arrayOf, node, object } from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Typography from "../Typography/Typography";

const RadioButtonGroup = ({
  groupLabel,
  radioGroupProps,
  items,
  classes,
  printItem,
  formControlLabelProps,
  formControlProps,
  itemLabelProps,
}) => {
  const titleize = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const isItemId = (item) => {
    const { value, key } = item;
    return value && key !== "id";
  };

  const label = (item) =>
    item?.label ? (
      <div className={classes?.legal_entity}>
        {printItem
          ? printItem?.(item.label)
          : Object.entries(item).map(
              ([key, value]) =>
                isItemId({ key, value }) && (
                  <Typography {...itemLabelProps}>
                    <strong>{titleize(key)}</strong>: {value}
                  </Typography>
                )
            )}
      </div>
    ) : (
      item
    );

  return (
    <FormControl classes={classes?.form_control} {...formControlProps}>
      <FormLabel classes={classes?.group_label}>{groupLabel}</FormLabel>
      <RadioGroup {...radioGroupProps}>
        {items?.map((item, index) => (
          <Fragment key={"radio-group-item-" + index}>
            <FormControlLabel
              control={<Radio classes={classes?.radio_button} disabled={item?.disabled} />}
              label={label(item)}
              value={item?.id || item}
              classes={classes?.form_control_label}
              {...formControlLabelProps}
            />
          </Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtonGroup.propTypes = {
  groupLabel: node,
  radioButtonProps: object,
  items: arrayOf(object),
  itemLabelProps: object,
  classes: PropTypes.object,
  printItem: PropTypes.func,
  formControlLabelProps: object,
  formControlProps: object,
};

export default RadioButtonGroup;
