import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    image_background: {
      height: "406px",
      display: "flex",
      alignItems: "center",
      backgroundImage:
        "url('https://tapinto-preview.s3.amazonaws.com/assets/images/real-estates/tapinto-real-estates-finder-background.png')",
    },
    contrast_background: {
      margin: "auto, 0px",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      height: "calc(100% - 42px)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "38px !important",
      fontWeight: 600,
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        fontSize: "20px !important",
      },
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    form_container: {
      width: "60%",
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "8px",
      [theme.breakpoints.down(760)]: {
        width: "90%",
      },
    },
    button: {
      height: theme.spacing(6),
      width: "25%",
      textTransform: "none !important",
      backgroundColor: `${theme.palette.miscellaneous.white} !important`,
      border: `2px solid ${theme.palette.dark.blue} !important`,
      borderRadius: "0px 8px 8px 0px !important",
      justifyContent: "flex-start !important",
      paddingLeft: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.down(760)]: {
        width: "40%",
      },
    },
    filters_button: {
      justifyContent: "space-between !important",
    },
    go_button: {
      width: "20%",
      height: "100%",
      justifyContent: "center !important",
      backgroundColor: `${theme.palette.themeColor.secondary} !important`,
      textTransform: "uppercase !important",
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "14px",
        fontFamily: "Montserrat",
        color: theme.palette.dark.black,
      },
    },
    go_button_label: {
      "& .MuiButton-label": {
        fontWeight: 800,
      },
    },
    autocomplete_container: {
      width: "100%",
      display: "flex",
      marginTop: theme.spacing(2),
      "& [class*='autocomplete']": {
        borderRadius: "8px 0px 0px 8px !important",
      },
      [theme.breakpoints.down(760)]: {
        marginTop: theme.spacing(1),
      },
    },
    autocomplete: {
      border: `2px solid ${theme.palette.dark.blue} !important`,
      borderRight: "0px !important",
      backgroundColor: theme.palette.miscellaneous.white,
      maxWidth: "80%",
      width: "80%",
      "& input": {
        opacity: "1 !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "none",
      },
      "& .MuiInputBase-input": {
        padding: `0px 0px 0px ${theme.spacing(1)}px !important`,
      },
      "& .MuiInput-underline::after": {
        borderBottom: "none !important",
      },
      "& .MuiAutocomplete-inputRoot": {
        flexWrap: "nowrap",
      },
      "& .MuiInputBase-root": {
        maxWidth: "100%",
        overflowY: "hidden",
        overflowX: "auto",
      },
      "& .MuiAutocomplete-endAdornment": {
        float: "right",
        position: "unset",
      },
    },
    autocomplete_options: {
      "& li": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
      },
      "& li:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      },
      '& li.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      },
      '& li.MuiAutocomplete-option[aria-selected="true"]:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      },
    },
    chip: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      margin: theme.spacing(1),
      maxWidth: 250,
      borderRadius: "50px !important",
      "& span": {
        color: `${theme.palette.dark.black} !important`,
      },
    },
    dropdown_container: {
      width: "50%",
      height: theme.spacing(6),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px !important",
      },
      [theme.breakpoints.down(760)]: {
        width: "100%",
        height: theme.spacing(6),
        marginBottom: theme.spacing(0.5),
      },
    },
    dropdown: {
      border: `2px solid ${theme.palette.dark.blue}`,
      borderRight: "0px",
      borderRadius: "8px 0px 0px 8px !important",
      height: "100% !important",
      "& .MuiFormControl-root, .MuiInputBase-root": {
        height: "100% !important",
      },
      "& .MuiInputBase-input": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        color: theme.palette.dark.black,
      },
      [theme.breakpoints.down(760)]: {
        borderRadius: "8px !important",
      },
    },
    dropdown_item: {
      width: "100%",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
    },
    property_type_select_container: {
      width: "25%",
      height: theme.spacing(6),
      backgroundColor: theme.palette.miscellaneous.white,
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.miscellaneous.white,
        width: "100%",
        height: "100%",
      },
      "& .MuiSelect-filled": {
        padding: theme.spacing(1.87),
        height: `theme.spacing(6)px !important`,
      },
      "& .MuiSelect-root": {
        boxSizing: "inherit",
        height: "100% !important",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottom: "none !important",
      },
      [theme.breakpoints.down(760)]: {
        width: "60%",
      },
    },
    property_type_select: {
      fontSize: "12px !important",
      fontWeight: "700 !important",
      color: `${theme.palette.dark.black} !important`,
      border: `2px solid ${theme.palette.dark.blue} !important`,
      borderRight: "none !important",
      [theme.breakpoints.down(760)]: {
        borderRadius: "8px 0px 0px 8px !important",
      },
    },
    property_type_select_menu_item: {
      fontSize: "16px !important",
      fontFamily: "Montserrat, sans-serif !important",
      fontWeight: "400 !important",
    },
  };
});

export { useStyles };
