import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { useStyles } from "./ApplyForExemptionTownSelectModal.style";
import Typography from "../../../common/Typography/Typography";
import BubbledButton from "../../../common/Button/BubbledButton";
import TownPicker from "../../TownPicker/TownPicker";
import {
  getDropdownChoices,
  filterMainTownFromDropdownChoices,
  loadTownsWithClusters
} from "../../../services/townService";
import { applyForExemption } from "../../../services/exemption";
import Checkbox from "../../../common/Checkbox/Checkbox";
import TextField from "../../../common/TextField/TextField";
import { isValidString } from "../../../utils/helper";

const SIGN_IN_PATH = "/users/sign_in";
const OK = 200;
const DESCRIPTION_CHARACTER_LIMIT = 500;

const ApplyForExemptionTownSelectModal = ({
  envUrl,
  townsWithClusters,
  currentUserId,
  handleSuccess,
  setSuccessMessage,
  onRequestSent,
}) => {
  const classes = useStyles();
  const [mainTownId, setMainTownId] = useState();
  const [item, setItem] = useState(true);
  const [multipleTowns, setMultipleTowns] = useState([]);
  const [errorSubmitRequestMessage, setErrorSubmitRequestMessage] =
    useState("");
  useState("");
  const [showMultipleTownPicker, setShowMultipleTownPicker] = useState(false);
  const [exemptionDescription, setExemptionDescription] = useState("");
  const [nonProfitOrGovernmentName, setNonProfitOrGovernementName] =
    useState("");
  const [towns, setTowns] = useState([]);

  const handleTownSelection = (town) => {
    setMainTownId(town);
  };

  const handleMultipleTownsSelection = (tagsArray) => {
    setMultipleTowns(tagsArray);
  };

  const handleNullItem = (itemValue) => {
    setItem(itemValue);
  };

  const getAdditionalTownIds = () => {
    const additionalTowns = multipleTowns.map((town) => town.id);
    return additionalTowns;
  };

  const shouldMarkError = () => {
    if (
      isValidString(exemptionDescription) &&
      exemptionDescription.length <= 500 &&
      isValidString(nonProfitOrGovernmentName)
    ) {
      return false;
    }
    return true;
  };

  const handleSubmitRequest = async () => {
    shouldMarkError();

    if (!currentUserId) {
      window.location.href = `${envUrl}${SIGN_IN_PATH}`;
    }

    if (shouldMarkError() === false && mainTownId) {
      try {
        const exemptionResponse = await applyForExemption(
          currentUserId,
          mainTownId,
          getAdditionalTownIds(),
          nonProfitOrGovernmentName,
          exemptionDescription
        );
        // Enable next line if want to test with a fake response
        // const exemptionResponse = {
        //   status: 200,
        //   data: {
        //     exemption_saved: true,
        //     errors: [],
        //   },
        // };

        console.log({ exemptionResponse });

        if (
          exemptionResponse.status === OK &&
          exemptionResponse?.data?.exemption_saved
        ) {
          handleSuccess(true);
          setSuccessMessage(labels.SUCCESSFUL_REQUEST);
          onRequestSent();
        } else {
          setErrorSubmitRequestMessage(labels.UNSUCCESSFUL_REQUEST);
        }
      } catch (error) {
        setErrorSubmitRequestMessage(
          `${labels.UNSUCCESSFUL_REQUEST_1} ${error}`
        );
      }
    } else if (shouldMarkError()) {
      setErrorSubmitRequestMessage(labels.EMPTY_DESCRIPTION_OR_NAME_ERROR);
      return;
    }
  };

  const isMainTownPresent = () => {
    if (item) {
      return true;
    }
    return false;
  };

  const disableMultipleTownPickerAndSubmitButton = () => {
    if (!item || !mainTownId) {
      return true;
    }
    return false;
  };

  const loadTowns = async () => {
    const response = await loadTownsWithClusters();
    if (
      response?.status === 200 &&
      response?.data?.towns_with_clusters?.length > 0
    ) {
      const townsFormatted = response.data.towns_with_clusters.map((town) => {
        return { name: town.name, id: town.id };
      });
      setTowns(townsFormatted);
    }
  };

  useEffect(() => {
    loadTowns();
  }, []);

  return (
    <div className={classes.modal_content}>
      {errorSubmitRequestMessage && (
        <div className={classes.submit_error_container}>
          <Typography level="t2_text_2" color="red" bold>
            {errorSubmitRequestMessage}
          </Typography>
        </div>
      )}

      {!isMainTownPresent() && (
        <div className={classes.multiple_towns_disabled_error_container}>
          <Typography level="t1_text_1" color="red" bold>
            {labels.ERROR_MESSAGE_MULTIPLE_TOWNS_DISABLED}
          </Typography>
        </div>
      )}

      <div className={classes.name_container}>
        <TextField
          onChange={(e) => setNonProfitOrGovernementName(e.target.value)}
          label={labels.GOVERNEMENT_OR_NON_PROFIT_NAME}
          labelColor="tapintoOrange"
          labelLevel="paragraph_1"
          className={classes.name_more_about}
          error={isValidString(errorSubmitRequestMessage) ? true : false}
          required
        />
      </div>

      <div className={classes.description_container}>
        <TextField
          onChange={(e) => setExemptionDescription(e.target.value)}
          label={labels.TELL_US_MORE_ABOUT}
          labelColor="tapintoOrange"
          labelLevel="paragraph_1"
          className={classes.name_more_about}
          isMultiline={true}
          error={isValidString(errorSubmitRequestMessage) ? true : false}
          required
          inputProps={{
            maxLength: DESCRIPTION_CHARACTER_LIMIT,
          }}
          helperText={`${exemptionDescription.length}/${DESCRIPTION_CHARACTER_LIMIT}`}
        />
      </div>

      <div className={classes.text_container}>
        <Typography level="paragraph_1" bold color={"orange"}>
          {labels.EXEMPTION_MAIN_TOWN}
        </Typography>
      </div>

      {towns && (
        <TownPicker
          envUrl={envUrl}
          dropdownAutocompleteClass={classes.main_town_picker}
          isTownPickerMobile={false}
          townsAsItems={getDropdownChoices(towns, handleTownSelection)}
          setItem={handleNullItem}
          withNoTownOption={false}
        />
      )}

      <div className={classes.text_checkbox_container}>
        <Typography level="paragraph_1" bold color={"tapintoOrange"}>
          {labels.EXEMPTION_ADDITIONAL_TOWNS}
        </Typography>
        <div>
          <Checkbox
            disabled={disableMultipleTownPickerAndSubmitButton()}
            checked={showMultipleTownPicker}
            onChange={() => setShowMultipleTownPicker(!showMultipleTownPicker)}
            className={classes.additional_towns_checkbox}
          />
        </div>
      </div>

      {showMultipleTownPicker &&
        disableMultipleTownPickerAndSubmitButton() === false && (
          <TownPicker
            isMultipleWithTags={true}
            envUrl={envUrl}
            townsAsItems={getDropdownChoices(
              filterMainTownFromDropdownChoices(towns, mainTownId),
              handleTownSelection
            )}
            setTagsArray={handleMultipleTownsSelection}
            isTownPickerMobile={false}
            withNoTownOption={false}
          />
        )}

      <div className={classes.disclaimer_container}>
        <Typography level="t2_text_2" color={"grey"}>
          {labels.EXEMPTION_DISCLAIMER_1}
          <br />
          <br />
          {labels.EXEMPTION_DISCLAIMER_2}
        </Typography>
      </div>

      <div className={classes.button_container}>
        <BubbledButton
          level="button_orange"
          color="primary"
          disabled={disableMultipleTownPickerAndSubmitButton()}
          className={
            mainTownId && item
              ? classes.submit_request_button
              : classes.disabled_submit_request_button
          }
          onClick={handleSubmitRequest}
        >
          {labels.SUBMIT_REQUEST}
        </BubbledButton>
      </div>
    </div>
  );
};

ApplyForExemptionTownSelectModal.propTypes = {
  envUrl: PropTypes.string,
  townsWithClusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  currentUserId: PropTypes.number,
  handleSuccess: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  onRequestSent: PropTypes.func,
};

export default ApplyForExemptionTownSelectModal;
