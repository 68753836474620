import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
  } = theme;

  return {
    pdf_actions_pop_up: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: spacing(129),
        paddingLeft: spacing(8),
        paddingRight: spacing(8),
        paddingBottom: spacing(6),
        [below(values.sm)]: {
          maxWidth: spacing(62.5),
          padding: spacing(1),
          margin: spacing(2),
        },
      },
    },
    title: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      backgroundColor: "white",
      borderBottom: "1px solid black",
      padding: `0 0 0 ${spacing(1)}px !important`,
      "& span": {
        fontSize: spacing(2),
        fontStyle: "italic",
        alignSelf: "center",
      },
    },
    row: {
      display: "flex",
    },
    close_button: {
      width: spacing(3),
      height: spacing(3),
      cursor: "pointer",
      position: "absolute",
      right: spacing(3),
      top: spacing(2),
    },
    content: {
      overflowY: "unset !important",
    },
    container: {
      justifyContent: "space-between",
      marginTop: `${spacing(3)}px !important`,
    },
    checkbox_container: {
      width: "5%",
      display: "flex",
    },
    thumbnail_container: {
      width: 100,
      height: "auto",
      backgroundColor: "#EEEEEE",
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        verticalAlign: "middle !important",
      },
    },
    filename_container: {
      width: "45%",
      alignSelf: "center",
    },
    filename: {
      display: "block",
    },
    size: {
      display: "block",
      marginTop: spacing(1),
    },
    actions: {
      width: "35%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    edit_button: {
      display: "block",
    },
    edit_name_button: {
      padding: spacing(0.25) + "px !important",
    },
    edit_button_text: {
      display: "block",
      height: spacing(2),
      padding: spacing(1),
    },
    no_title: {
      display: "block",
    },
    edit_name_text_field: {
      "& input": {
        width: "100% !important",
      },
    },
    file_name_container: {
      display: "flex",
      gap: spacing(1),
      width: "100%",
    },
  };
});

export { useStyles };
