import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    select: {
      fontFamily: "Montserrat, sans-serif !important",
    },
  };
});

export { useStyles };
