import React from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import { GRAY } from "../../diyConstants";
import { useStyles } from "./AdsFreeList.style";
import AdsFreeShoppingListRow from "../AdsFreeShoppingListRow/AdsFreeShoppingListRow";

const AdsFreeList = ({ townsSelected, itemCount }) => {
  const classes = useStyles();

  const townsCount = () => {
    const selected = townsSelected.filter(
      (town) => town.ads_free_selected
    ).length;
    return ` (${selected} / ${townsSelected.length})`;
  };

  return (
    <div className={classes.facebook_line_items_container}>
      <Typography level="paragraph_2" bold>
        {labels.SHOPPING_CART_ADS_FREE}
        {townsCount()}
      </Typography>
      <div
        className={
          townsSelected.length > 0 ? classes.with_items : classes.empty
        }
      >
        {townsSelected.length > 0 ? (
          townsSelected.map((town) => (
            <AdsFreeShoppingListRow
              key={`ads-free-town-shopping-cart-item-${town.id}`}
              town={town}
              itemQuantity={itemCount}
            />
          ))
        ) : (
          <Typography level="t1_text_1" color={GRAY} bold>
            {labels.NO_ADS_FREE_TOWNS_SELECTED}
          </Typography>
        )}
      </div>
    </div>
  );
};

AdsFreeList.propTypes = {
  townsSelected: PropTypes.array,
  itemCount: PropTypes.number,
};

export default AdsFreeList;
