import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import { FACEBOOK_BLUE, GRAY } from "../../diyConstants";
import { useStyles } from "./FacebookList.style";
import FacebookShoppingListRow from "../FacebookShoppingListRow/FacebookShoppingListRow";

const FacebookList = ({ facebookTownsSelected, itemCount }) => {
  const [townsFiltered, setTownsFiltered] = useState([]);
  useEffect(() => {
    if (facebookTownsSelected.length > 0) {
      setTownsFiltered(
        facebookTownsSelected.filter(
          (town) =>
            town.main_town_id == null && town.is_main_town_license == null
        )
      );
    }
  }, [facebookTownsSelected]);

  const classes = useStyles();

  const townsCount = () => {
    const selected = townsFiltered.filter((town) => town.fb_selected).length;
    return ` (${selected} / ${townsFiltered.length})`;
  };

  return (
    <div className={classes.facebook_line_items_container}>
      <Typography level="paragraph_2" bold color={FACEBOOK_BLUE}>
        {labels.SHOPPING_CART_FACEBOOK}
        {townsCount()}
      </Typography>
      <div
        className={
          townsFiltered.length > 0 ? classes.with_items : classes.empty
        }
      >
        {townsFiltered.length > 0 ? (
          townsFiltered.map(
            (town) =>
              town.main_town_id == null &&
              !town.is_main_town_license && (
                <FacebookShoppingListRow
                  key={`town-shopping-cart-item-${town.id}`}
                  town={town}
                  isFacebook={true}
                  itemQuantity={itemCount}
                />
              )
          )
        ) : (
          <Typography level="t1_text_1" color={GRAY} bold>
            {labels.NO_FACEBOOK_SELECTED}
          </Typography>
        )}
      </div>
    </div>
  );
};

FacebookList.propTypes = {
  facebookTownsSelected: PropTypes.array,
  itemCount: PropTypes.number,
};

export default FacebookList;
