import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useStyles } from "./LeftBlocks.style";
import { useSection } from "../../../context/SectionContext";
import { useSubsection } from "../../../context/SubsectionContext";
import Box from "../../../common/Box/Box";

import {
  AD_BLOCK,
  SECTION_BLOCK,
  SUBSECTION_BLOCK,
  CATEGORY_BLOCK,
  TITLE_BLOCK,
  VIDEO_PLAYER_OPTIONS,
} from "../../../utils/constants/homepageConstants";
import HorizontalLayout from "../../../common/Card/Layouts/Horizontal/HorizontalLayout";
import VerticalLayout from "../../../common/Card/Layouts/Vertical/VerticalLayout";
import Card from "../../../common/Card/Card";
import ContentHeader from "../Shared/ContentHeader/ContentHeader";
import ContentInfo from "../Shared/ContentInfo/ContentInfo";
import CardActions from "../Shared/CardActions/CardActions";
import labels from "../../../config/labels";
import AdCard from "../../Homepage/AdCard/AdCard";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import Link from "../../../common/Link/Link";
import { isBlock } from "../../../utils/helpers/homepageHelper";
import BlockTitle from "../../Town/Shared/BlockTitle/BlockTitle";
import ContentTitle from "../../Town/LeftBlocks/Titles/ContentTile/ContentTitle";
import Contents from "../../Town/LeftBlocks/Contents/Contents";
import { NYVT_MEDIA_SLUG } from "../../../utils/constants/licenseConstants";

const LeftBlocks = ({ adTargets, section, subsection = null, town = null }) => {
  const useSectionContext = section ? true : false;
  const useSubsectionContext = useSectionContext && subsection ? true : false;
  const classes = useStyles();
  const selectContext = () => {
    if (useSubsectionContext) {
      return useSubsection();
    }
    return useSection();
  };
  const { leftBlocks } = selectContext();
  const isMobileVersion = screenWidthIsLowerThan(768);

  const Title = ({ block }) => {
    if (
      block?.title ||
      (block?.title && block?.contents?.length === 1) ||
      (block?.title &&
        block?.contents?.length === 1 &&
        screenWidthIsLowerThan(768)) ||
      (block?.title && block?.contents?.length !== 1)
    ) {
      let location = window.location.href;
      location = location.replace(/\?$/, "");
      if (block.class != CATEGORY_BLOCK) {
        location = location + "/" + block.slug;
      } else {
        location = location + "/categories/" + block.slug;
      }
      return (
        <ContentTitle block={block} isCustom={true} customURL={location} />
      );
    }
    return <></>;
  };
  const SectionBlock = leftBlocks
    ?.filter((block) => block.class == SECTION_BLOCK)
    .map((block) => block)
    .pop();
  const adBlocks = leftBlocks
    ?.filter((block) => block.class == AD_BLOCK)
    .map((block) => block);
  return (
    <>
      <div
        className={
          town?.slug != NYVT_MEDIA_SLUG ? classes.left_block_container : ""
        }
      >
        {leftBlocks.map((block, index) => {
          if (
            block.class == SUBSECTION_BLOCK ||
            block.class == CATEGORY_BLOCK
          ) {
            return (
              <div
                key={"single-card-container-" + index}
                className={classes.left_block_container_subsections}
              >
                {isBlock({ blockClass: block?.class, type: TITLE_BLOCK }) ? (
                  block.title ? (
                    <div
                      className={classes.title_block_container}
                      key={"title-block-" + index}
                    >
                      <BlockTitle title={block.title} />
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <Box key={"ad-box-" + index} className={classes.container}>
                    <Title block={block} />
                    <Contents
                      contents={block?.contents}
                      block={block}
                      {...(isBlock({
                        blockClass: block?.class,
                        type: AD_BLOCK,
                      }) && {
                        adProps: { ...block, ad_targets: adTargets },
                      })}
                      town={town}
                    />
                  </Box>
                )}
              </div>
            );
          } else if (block.class == SECTION_BLOCK) {
            return SectionBlock?.contents.map((content, index) => {
              const adBlockToRender = adBlocks
                ?.filter((block) => block.section_article_position == index + 1)
                .map((block) => block)
                .pop();
              const adUnits = isMobileVersion
                ? adBlockToRender?.mobile_ad_units
                : adBlockToRender?.desktop_ad_units;
              return (
                <div key={"single-card-container-" + index}>
                  <Fragment>
                    <Card
                      classes={{
                        root: clsx(
                          classes.content_card,
                          !adBlockToRender && classes.margin_bottom_without_ads
                        ),
                      }}
                      withShadow={true}
                      children={
                        isMobileVersion ? (
                          <VerticalLayout
                            media={content.media_content[0]}
                            information={
                              <ContentInfo
                                content={content}
                                blockClass={SectionBlock?.class}
                                asContentHeader={true}
                              />
                            }
                            options={{
                              caption: { visible: false },
                              credits: { visible: false },
                              media: {
                                clickable: true,
                                townContentUrl: content.url_content,
                                opacityOnFocus: true,
                              },
                              videoPlayer: VIDEO_PLAYER_OPTIONS,
                            }}
                            customClasses={{
                              image: classes.image,
                              media_container: classes.media_container,
                            }}
                          />
                        ) : (
                          <HorizontalLayout
                            header={
                              <ContentHeader
                                content={content}
                                blockClass={SectionBlock?.class}
                                forSingleCard={true}
                              />
                            }
                            media={content.media_content[0]}
                            information={
                              <ContentInfo
                                content={content}
                                blockClass={SectionBlock?.class}
                                forSingleCard={true}
                              />
                            }
                            actions={
                              <CardActions
                                contentUrl={content.url_content}
                                actionLabel={labels.READ_MORE}
                                withSocialMediaIcons={false}
                                withLink={true}
                              />
                            }
                            options={{
                              actions: {
                                placement: "below information",
                              },
                              media: {
                                clickable: true,
                                townContentUrl: content.url_content,
                                opacityOnFocus: true,
                              },
                              videoPlayer: VIDEO_PLAYER_OPTIONS,
                            }}
                            customClasses={{
                              image: clsx(classes.image),
                            }}
                          />
                        )
                      }
                    />
                  </Fragment>
                  {adBlockToRender && (
                    <div className={classes.ads_container}>
                      {adUnits.map((adUnit, index) => (
                        <Fragment key={"advertisement-" + index}>
                          <AdCard
                            adTargets={adTargets}
                            adUnit={adUnit}
                            adSize={adBlockToRender.size}
                          />
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              );
            });
          }
        })}
        <div className={classes.read_more_section_container}>
          <Link
            children={
              subsection
                ? `${labels.READ_MORE_SECTION} ${subsection.name}`
                : section
                ? `${labels.READ_MORE_SECTION} ${section.name}`
                : ""
            }
            url={subsection ? "" : section?.read_more_url || ""}
            className={classes.link}
            level="medium_11_px"
          />
        </div>
      </div>
    </>
  );
};

LeftBlocks.propTypes = {
  adTargets: PropTypes.oneOfType([
    PropTypes.shape({
      towns: PropTypes.arrayOf(PropTypes.string),
      sections: PropTypes.arrayOf(PropTypes.string),
      sponsor_display: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        towns: PropTypes.arrayOf(PropTypes.string),
        sections: PropTypes.arrayOf(PropTypes.string),
        sponsor_display: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  ]),
};

export default LeftBlocks;
