import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import Typography from "../../../../common/Typography/Typography";
import Checkbox from "../../../../common/Checkbox/Checkbox";
import { useStyles } from "./EventFields.style";
import EventDate from "./EventDate/EventDate";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  EVENT_NAME,
  ADDRESS,
  CITY,
  STATE,
  EVENT_DATE_STARTS,
  EVENT_START_TIME,
  GRAY,
  GREEN,
  ORANGE,
  MANDATORY_FIELDS_FOR_EVENT,
  ZIPCODE,
  CI_NAME_ADMIN,
  CI_PHONE_ADMIN,
  CI_NAME,
  CI_PHONE,
  CI_EMAIL,
  CI_WEBSITE,
  CI_FACEBOOK,
  CI_TWITTER,
  CI_INSTAGRAM,
  EVENT_END_TIME,
  EVENT_DATE_ENDS,
  VENUE,
  FACEBOOK_BLUE,
  ALL_DAY,
  VIRTUAL_EVENT,
  VIRTUAL_EVENT_URL,
  EVENT_DATES,
  ITEMS_COUNT,
  CONTENT_ID,
  DATES_TO_REMOVE,
  EVENT_DATE_ID,
  VIRTUAL_EVENT_PLACEHOLDER,
  X_BLACK,
  INSTAGRAM_PINK,
  IS_SAME_CONTACT_INFO,
} from "../../diyConstants";
import { format } from "date-fns";
import Dropdown from "../../../../common/Dropdown/Dropdown";
import {
  screenWidthIsLowerThan,
  screenWidthIsHigherThan,
} from "../../../../hooks/useMediaQuery";
import { getDropdownChoices } from "../../../../services/townService";
import { useDiy, withDiyContextProvider } from "../../../../context/DiyContext";
import cache from "../../../../utils/cache";
import Link from "../../../../common/Link/Link";

const DATE_FORMAT = "MM/dd/yyyy";
const today = new Date();
const tomorrow = new Date(today);
const noon = new Date("2022-01-01 12:00");
const one_pm = new Date("2022-01-01 13:00");

tomorrow.setDate(tomorrow.getDate() + 1);

const EventFields = ({
  formErrors,
  usStates,
  content,
  isEditForm,
  isDraft,
  eventDraftDatesCount,
  adFree,
}) => {
  const classes = useStyles();
  const { addItemCountToCart } = useDiy();
  const { setProperty } = useSubmitableContentForm();
  const [eventStartDate, setEventStartDate] = useState(
    content && content.starts ? content.starts : format(today, DATE_FORMAT)
  );
  const [chosenStateId, setChosenStateId] = useState();
  const [publicName, setPublicName] = useState(content?.ci_name || "");
  const [publicPhone, setPublicPhone] = useState(content?.ci_phone || "");
  const [adminName, setAdminName] = useState(content?.ci_name_admin || "");
  const [adminPhone, setAdminPhone] = useState(content?.ci_phone_admin || "");
  const [isVirtualEventCheckboxSelected, setIsVirtualEventCheckboxSelected] =
    useState(content?.virtual_event ? content?.virtual_event : false);
  const [isSameContactInfo, setIsSameContactInfo] = useState(
    content?.copy_contact_info ? content?.copy_contact_info : false
  );
  const [eventDateCounter, setEventDateCounter] = useState(
    eventDraftDatesCount ? eventDraftDatesCount : 1
  );
  const [eventDates, setEventDates] = useState(content?.event_dates || []);
  const [dateIdsToRemove, setDateIdsToRemove] = useState([]);

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_EVENT.find(
      (mandatory) => mandatory.key === prop
    );
    if (formErrors.includes(validation.error)) {
      return true;
    }

    return false;
  };

  const handleVirtualEventCheckboxChange = () => {
    setIsVirtualEventCheckboxSelected(!isVirtualEventCheckboxSelected);
  };

  const handleSameContactInfoCheckboxChange = (e) => {
    setIsSameContactInfo(!isSameContactInfo);
    const selected = e.target.checked;
    if (selected) {
      setPublicName(adminName);
      setPublicPhone(adminPhone);
      setProperty(CI_NAME, adminName);
      setProperty(CI_PHONE, adminPhone);
    } else {
      setPublicName("");
      setPublicPhone("");
      setProperty(CI_NAME, "");
      setProperty(CI_PHONE, "");
    }
  };

  const handleEventDateRemoval = (val, id) => {
    let new_event_dates = eventDates.filter((d) => d.index !== val);
    setEventDates(new_event_dates);
    setProperty(EVENT_DATES, new_event_dates);
    if (isDraft && id) {
      setDateIdsToRemove([...dateIdsToRemove, id]);
    }
  };

  const handleEventDateAddition = () => {
    setEventDateCounter(eventDateCounter + 1);
    let new_event_dates = eventDates.concat({
      index: eventDateCounter,
      date: today,
      start_time: noon,
      end_time: one_pm,
      all_day: false,
    });
    setEventDates(new_event_dates);

    if (isDraft) {
      setProperty(EVENT_DATES, new_event_dates);
    } else {
      setProperty(EVENT_DATES, new_event_dates);
    }
  };

  const handleEventDateChange = (event_date) => {
    if (event_date.index === 0) {
      setProperty(EVENT_DATE_STARTS, event_date.date);
      setProperty(EVENT_DATE_ENDS, event_date.date);
      setProperty(EVENT_START_TIME, event_date.start_time);
      setProperty(EVENT_END_TIME, event_date.end_time);
      setProperty(ALL_DAY, event_date.all_day);
    }
    let existingEventDate = eventDates.find(
      (d) => d.index === event_date.index
    );
    if (existingEventDate != undefined) {
      existingEventDate.date = event_date.date;
      existingEventDate.start_time = event_date.start_time;
      existingEventDate.end_time = event_date.end_time;
      existingEventDate.all_day = event_date.all_day;
    }
  };

  const handleNameAndPhoneChange = (e) => {
    switch (e.target.id) {
      case "ci_name":
        setPublicName(e.target.value);
        setProperty(CI_NAME, e.target.value);
        break;
      case "ci_phone":
        setPublicPhone(e.target.value);
        setProperty(CI_PHONE, e.target.value);
        break;
      case "ci_name_admin":
        setAdminName(e.target.value);
        setProperty(CI_NAME_ADMIN, e.target.value);
        break;
      case "ci_phone_admin":
        setAdminPhone(e.target.value);
        setProperty(CI_PHONE_ADMIN, e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setProperty(VIRTUAL_EVENT, isVirtualEventCheckboxSelected);
  }, [isVirtualEventCheckboxSelected]);

  useEffect(() => {
    setProperty(IS_SAME_CONTACT_INFO, isSameContactInfo);
  }, [isSameContactInfo]);

  useEffect(() => {
    setProperty(EVENT_NAME, content?.name);
    setProperty(ADDRESS, content?.address);
    setProperty(CITY, content?.city);
    setProperty(STATE, content?.state);
    setProperty(ZIPCODE, content?.zip_code);
    setProperty(VENUE, content?.venue);
    setProperty(CI_NAME_ADMIN, content?.ci_name_admin);
    setProperty(CI_PHONE_ADMIN, content?.ci_phone_admin);
    setProperty(CI_NAME, content?.ci_name);
    setProperty(CI_PHONE, content?.ci_phone);
    setProperty(CI_EMAIL, content?.ci_email);
    setProperty(CI_WEBSITE, content?.website);
    setProperty(CI_FACEBOOK, content?.facebook);
    setProperty(CI_INSTAGRAM, content?.instagram);
    setProperty(CI_TWITTER, content?.twitter);
    setProperty(ALL_DAY, content?.all_day);
    setProperty(EVENT_DATES, content?.event_dates);
    setProperty(VIRTUAL_EVENT, content?.virtual_event);
    setProperty(VIRTUAL_EVENT_URL, content?.virtual_event_url);
    setProperty(EVENT_DATE_STARTS, content?.starts);
    setProperty(EVENT_START_TIME, content?.starts_for_diy);
    setProperty(EVENT_DATE_ENDS, content?.ends);
    setProperty(EVENT_END_TIME, content?.ends_for_diy);
    setProperty(CONTENT_ID, content?.id);
    setProperty(ALL_DAY, content?.all_day);
    setProperty(IS_SAME_CONTACT_INFO, content?.copy_contact_info);
  }, [content]);

  useEffect(() => {
    setProperty(DATES_TO_REMOVE, dateIdsToRemove);
  }, [dateIdsToRemove]);

  useEffect(() => {
    setProperty(EVENT_DATE_STARTS, eventStartDate);
  }, [eventStartDate]);

  const handleStateChoice = (choice) => {
    setChosenStateId(choice);
  };

  useEffect(() => {
    setProperty(STATE, chosenStateId);
  }, [chosenStateId]);

  useEffect(() => {
    cache.set(ITEMS_COUNT, eventDates.length + 1);
    addItemCountToCart();
  }, [eventDates]);

  return (
    <>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(EVENT_NAME, e.target.value)}
          label={labels.EVENT_NAME}
          error={shouldMarkError(EVENT_NAME)}
          defaultValue={content?.name}
          required
        />
      </div>
      <EventDate
        eventDateCounter={0}
        onChange={handleEventDateChange}
        onRemoval={handleEventDateRemoval}
        starts={eventStartDate}
        eventStartTime={content?.start_time}
        eventEndTime={content?.end_time}
        key={`event-date-service-${0}`}
        allDay={content?.all_day}
      />

      {eventDates.map((date) => {
        return (
          <EventDate
            eventDateCounter={date.index}
            onChange={handleEventDateChange}
            onRemoval={handleEventDateRemoval}
            starts={date.date}
            key={`event-date-service-${date.index}`}
            eventStartTime={date.start_time}
            eventEndTime={date.end_time}
            isMainDate={false}
            dateId={date.id}
            allDay={date.all_day}
          />
        );
      })}

      {!isEditForm && (
        <div className={classes.link_container}>
          <Link
            level={screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px"}
            color={ORANGE}
            bold
            onClick={handleEventDateAddition}
          >
            {labels.ADD_MORE}
          </Link>
        </div>
      )}
      <div>
        <Checkbox
          checked={isVirtualEventCheckboxSelected}
          onChange={() => handleVirtualEventCheckboxChange()}
          className={classes.event_virtual_event_checkbox}
        />
        <Typography
          level={screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px"}
          color={GRAY}
          bold
        >
          {labels.VIRTUAL_EVENT}
        </Typography>
      </div>
      {isVirtualEventCheckboxSelected && (
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setProperty(VIRTUAL_EVENT_URL, e.target.value)}
            label={labels.VIRTUAL_EVENT_URL}
            defaultValue={content?.virtual_event_url}
            placeholder={VIRTUAL_EVENT_PLACEHOLDER}
          />
        </div>
      )}
      {!isVirtualEventCheckboxSelected && (
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setProperty(ADDRESS, e.target.value)}
            label={labels.EVENT_ADDRESS}
            defaultValue={content?.address}
          />
        </div>
      )}
      {!isVirtualEventCheckboxSelected && (
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setProperty(VENUE, e.target.value)}
            label={labels.VENUE}
            defaultValue={content?.venue}
          />
        </div>
      )}
      {screenWidthIsHigherThan(900) && !isVirtualEventCheckboxSelected && (
        <div className={classes.multiple_textfields_container}>
          <div className={classes.multiple_textfields}>
            <TextField
              onChange={(e) => setProperty(CITY, e.target.value)}
              label={labels.CITY}
              defaultValue={content?.city}
            />
          </div>
          <div className={classes.multiple_textfields}>
            <Dropdown
              label={labels.STATE}
              items={getDropdownChoices(usStates, handleStateChoice, false)}
              getChosenOptionId={handleStateChoice}
              typographyLevel={"h4"}
              shouldPreselectFirstOption={false}
              {...(content && {
                defaultValue: {
                  label: content?.state,
                  action: () =>
                    handleStateChoice(
                      usStates.find((s) => s.name === content?.state)?.id
                    ),
                  labelComponent: content?.state,
                },
              })}
            />
          </div>
          <div className={classes.multiple_textfields}>
            <TextField
              onChange={(e) => setProperty(ZIPCODE, e.target.value)}
              label={labels.ZIP_CODE}
              defaultValue={content?.zip_code}
            />
          </div>
        </div>
      )}
      {screenWidthIsLowerThan(899) && !isVirtualEventCheckboxSelected && (
        <>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(CITY, e.target.value)}
              label={labels.CITY}
              defaultValue={content?.city}
            />
          </div>
          <div className={classes.regular_text_field}>
            <Dropdown
              label={labels.STATE}
              items={getDropdownChoices(usStates, handleStateChoice, false)}
              getChosenOptionId={handleStateChoice}
              typographyLevel={"medium_17_px"}
              shouldPreselectFirstOption={false}
              defaultValue={{
                label: content?.state,
                action: () =>
                  handleStateChoice(
                    usStates.find((s) => s.name === content?.state)?.id
                  ),
                labelComponent: content?.state,
              }}
            />
          </div>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(ZIPCODE, e.target.value)}
              label={labels.ZIP_CODE}
              defaultValue={content?.zip_code}
            />
          </div>
        </>
      )}
      <div className={classes.padding_top_16}>
        <Typography
          level={screenWidthIsLowerThan(900) ? "medium_17_px" : "h4"}
          bold
          color={GREEN}
        >
          {labels.PRIVATE_CONTACT_INFO + " "}
        </Typography>
      </div>
      <div className={classes.padding_top_bottom_24}>
        <Typography
          level={
            screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
          }
          color={GRAY}
          className={classes.display_block}
        >
          {labels.THIS_WILL_BE_SEEN_BY_ADMIN}
        </Typography>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          id={"ci_name_admin"}
          onChange={handleNameAndPhoneChange}
          value={adminName}
          label={labels.CONTACT_PERSON}
          error={shouldMarkError(CI_NAME_ADMIN)}
          defaultValue={content?.ci_name_admin}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          id={"ci_phone_admin"}
          onChange={handleNameAndPhoneChange}
          label={labels.PHONE}
          value={adminPhone}
          error={shouldMarkError(CI_PHONE_ADMIN)}
          defaultValue={content?.ci_phone_admin}
          required
        />
      </div>
      <div className={classes.padding_top_bottom_24}>
        <Checkbox
          checked={isSameContactInfo}
          disabled={adminName === "" || adminPhone === ""}
          onChange={handleSameContactInfoCheckboxChange}
          className={classes.event_virtual_event_checkbox}
        />
        <Typography
          level={screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px"}
          color={GRAY}
          bold
        >
          {labels.EVENT_SAME_CONTACT_INFO}
        </Typography>
      </div>
      <div className={classes.padding_top_bottom_24}>
        <Typography
          level={
            screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
          }
          color={GRAY}
          className={classes.display_block}
        >
          {labels.THIS_WILL_BE_SEEN_BY_PUBLIC}
        </Typography>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          id={"ci_name"}
          onChange={handleNameAndPhoneChange}
          value={isSameContactInfo ? adminName : publicName}
          label={labels.CONTACT_PERSON}
          defaultValue={content?.ci_name}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          id={"ci_phone"}
          onChange={handleNameAndPhoneChange}
          value={isSameContactInfo ? adminPhone : publicPhone}
          label={labels.PHONE_NUMBER}
          defaultValue={content?.ci_phone}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(CI_EMAIL, e.target.value)}
          label={labels.EMAIL}
          defaultValue={content?.ci_email}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(CI_WEBSITE, e.target.value)}
          label={labels.WEBSITE}
          defaultValue={content?.website}
          placeholder={VIRTUAL_EVENT_PLACEHOLDER}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(CI_FACEBOOK, e.target.value)}
          label={labels.FACEBOOK}
          labelColor={FACEBOOK_BLUE}
          defaultValue={content?.facebook}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(CI_INSTAGRAM, e.target.value)}
          label={labels.INSTAGRAM}
          labelColor={INSTAGRAM_PINK}
          defaultValue={content?.instagram}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(CI_TWITTER, e.target.value)}
          label={labels.TWITTER}
          labelColor={X_BLACK}
          defaultValue={content?.twitter}
        />
      </div>
    </>
  );
};

EventFields.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
  usStates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  isEditForm: PropTypes.bool,
  isDraft: PropTypes.bool,
  eventDraftDatesCount: PropTypes.number,
  content: PropTypes.shape({
    id: PropTypes.number,
    organization_type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    venue: PropTypes.string,
    ci_name_admin: PropTypes.string,
    ci_phone_admin: PropTypes.string,
    ci_name: PropTypes.string,
    ci_phone: PropTypes.string,
    ci_email: PropTypes.string,
    website: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    all_day: PropTypes.bool,
    event_dates: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.string,
        ]),
        all_day: PropTypes.bool,
        start_time: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.string,
        ]),
        end_time: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.string,
        ]),
      })
    ),
    starts: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    ends: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    start_time: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    end_time: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
};

export default withDiyContextProvider(EventFields);
