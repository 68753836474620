import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    obituaries_list: {
      width: "95%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    all_obituaries: {
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2.25),
      [theme.breakpoints.down(960)]: {
        textAlign: "center",
      },
    },
    obituaries_pagination: {
      "& .Mui-selected": {
        backgroundColor: "unset !important",
        color: theme.palette.themeColor.secondary,
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        fontWeight: "bold",
      },
    },
    pagination_container: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    no_search_results: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    obituary_list_button: {
      borderRadius: "0px !important",
      width: theme.spacing(20.6),
      height: theme.spacing(4),
      backgroundColor: `${theme.palette.grey.A200} !important`,
      [theme.breakpoints.down(960)]: {
        minWidth: theme.spacing(18),
        height: theme.spacing(2.6),
      },
      marginBottom: `${theme.spacing(0.0625)}rem!important`,
    },
    button_label: {
      color: theme.palette.common.white,
      textTransform: "capitalize",
      fontFamily: "Montserrat, sans-serif",
      fontSize: `${theme.spacing(1.5)}px`,
      [theme.breakpoints.down(960)]: {
        fontSize: `${theme.spacing(1.25)}px`,
      },
    },
  };
});

export { useStyles };
