import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import { GRAY, LETTERS_TO_THE_EDITOR } from "../../diyConstants";
import ShoppingListRow from "../ShoppingListRow/ShoppingListRow";
import {
  handleTownRemoval,
  updateItemCountListener,
} from "../../../../utils/listeners";
import { useStyles } from "./ShoppingList.style";
import FacebookList from "../FacebookList/FacebookList";
import AdsFreeList from "../AdsFreeList/AdsFreeList";
import IncludeInEnews from "../IncludeInEnewsList/IncludeInEnews";

const ShoppingList = ({
  townsSelected,
  formType,
  showFacebookBlock,
  showAdsFreeBlock,
  showIncludeInEnews,
  enewsDatesSelected,
  itemCountForEventDraft,
  town,
  onRemoveTown,
  isAdvertiser,
  advertiserTownsIds,
}) => {
  const classes = useStyles();
  const [itemCount, setItemCount] = useState(
    itemCountForEventDraft ? itemCountForEventDraft : undefined
  );
  const [facebookTownsSelected, setFacebookTownsSelected] = useState([]);
  const [adsFreeTownsSelected, setAdsFreeTownsSelected] = useState([]);
  const [enewsTownsSelected, setEnewsTownsSelected] = useState([]);

  useEffect(() => {
    if (townsSelected?.length > 0) {
      setFacebookTownsSelected(
        townsSelected.filter(
          (town) =>
            town.main_town_id == null &&
            town.is_main_town_license == null &&
            town.fb_selected
        )
      );
      setEnewsTownsSelected(
        townsSelected.filter((town) => town.enews_selected)
      );
      setAdsFreeTownsSelected(
        townsSelected.filter((town) => town.ads_free_selected)
      );
      setEnewsTownsSelected(
        townsSelected.filter((town) => town.enews_selected)
      );
    } else {
      setFacebookTownsSelected([]);
      setAdsFreeTownsSelected([]);
      setEnewsTownsSelected([]);
      setEnewsTownsSelected([]);
    }
  }, [townsSelected]);

  updateItemCountListener(setItemCount);

  const getTotalPurchaseAmmount = useCallback(() => {
    const initialValue = 0;
    let total = 0;
    if (townsSelected && formType !== LETTERS_TO_THE_EDITOR) {
      total = townsSelected.reduce(
        (accumulator, current) => accumulator + current.price,
        initialValue
      );
      if (showFacebookBlock) {
        total += facebookTownsSelected.reduce(
          (accumulator, current) => accumulator + current.fb_price,
          initialValue
        );
      }
      if (showAdsFreeBlock) {
        total += adsFreeTownsSelected.reduce(
          (accumulator, current) => accumulator + current.ads_free_price,
          initialValue
        );
      }
      if (showIncludeInEnews) {
        total += enewsTownsSelected.reduce((accumulator, current) => {
          const datesQty = enewsDatesSelected?.length;
          return datesQty > 0
            ? accumulator + current.enews_price * datesQty
            : accumulator;
        }, initialValue);

        if (isAdvertiser) {
          advertiserTownsIds.forEach((advertiserTownId) => {
            const town = enewsTownsSelected.find(
              (town) => town.id === advertiserTownId
            );
            if (town) {
              total -= town.enews_price;
            }
          });
        }
      }
    }
    return itemCount ? itemCount * total : total;
  }, [
    townsSelected,
    facebookTownsSelected,
    adsFreeTownsSelected,
    enewsTownsSelected,
    showFacebookBlock,
    showAdsFreeBlock,
    showIncludeInEnews,
    enewsDatesSelected,
    itemCount,
  ]);

  const shouldDisplayFacebookSummary = () => {
    if ([LETTERS_TO_THE_EDITOR].includes(formType) || !showFacebookBlock) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div
        className={
          townsSelected.length > 0 ? classes.with_items : classes.empty
        }
      >
        {townsSelected.length > 0 ? (
          townsSelected.map((town) => (
            <ShoppingListRow
              key={`town-shopping-cart-item-${town.id}`}
              town={town}
              onRemove={handleTownRemoval}
              formType={formType}
              isFacebook={false}
              itemQuantity={itemCount}
            />
          ))
        ) : (
          <Typography level="t1_text_1" color={GRAY} bold>
            {town?.under_license_contract
              ? labels.NO_PUBLICATIONS_SELECTED
              : labels.NO_TOWNS_SELECTED}
          </Typography>
        )}
      </div>

      {showAdsFreeBlock && (
        <AdsFreeList townsSelected={townsSelected} itemCount={itemCount} />
      )}

      {shouldDisplayFacebookSummary() && (
        <FacebookList
          facebookTownsSelected={townsSelected}
          itemCount={itemCount}
        />
      )}
      {showIncludeInEnews && (
        <IncludeInEnews
          enewsTownsSelected={townsSelected}
          onRemoveTown={onRemoveTown}
          enewsDatesSelected={enewsDatesSelected}
          itemCount={itemCount}
          isAdvertiser={isAdvertiser}
          advertiserTownsIds={advertiserTownsIds}
        />
      )}

      <div className={classes.purchase_total_container}>
        <div className={classes.purchase_total_label}>
          <Typography level="t1_text_1" color={GRAY} bold>
            {labels.PURCHASE_TOTAL}
          </Typography>
        </div>
        <div className={classes.purchase_total_ammount}>
          <Typography level="t3_text_3" bold>
            {labels.DOLLAR_SIGN + getTotalPurchaseAmmount()}
          </Typography>
        </div>
      </div>
    </>
  );
};

ShoppingList.propTypes = {
  townsSelected: PropTypes.array,
  formType: PropTypes.string,
  showFacebookBlock: PropTypes.bool,
  itemCountForEventDraft: PropTypes.number,
};

export default ShoppingList;
