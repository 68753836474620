import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MaterialSelect,
} from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { useStyles } from "./Select.style";

const Select = ({
  selectItems,
  onChange,
  value,
  dropdownItemClasses,
  selectClasses,
  variant,
  disabled,
  menuItemsWithDivider,
  menuProps,
  label,
  color,
  name,
  helperText,
  error,
  className,
  formControlClassName,
}) => {
  const classes = useStyles({ color });

  return (
    <FormControl
      variant={variant}
      className={formControlClassName}
      error={error}
    >
      <InputLabel
        id="demo-simple-select-label"
        classes={{ focused: classes.focused }}
      >
        {label}
      </InputLabel>
      <MaterialSelect
        classes={{
          select: clsx(
            classes.select,
            selectClasses?.root && selectClasses?.root
          ),
          icon: selectClasses?.icon,
          filled: clsx(
            classes.filled_variant,
            selectClasses?.filled_variant && selectClasses?.filled_variant
          ),
          root: selectClasses?.root,
        }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        MenuProps={menuProps}
        defaultValue={selectItems[0]}
        label={label}
        name={name}
        className={className}
      >
        {selectItems.map((item, index) => {
          return (
            <MenuItem
              classes={{
                root: dropdownItemClasses?.root,
                divider: dropdownItemClasses?.divider,
                gutters: dropdownItemClasses?.gutter,
              }}
              key={index}
              value={typeof item === "object" ? item.value : item}
              divider={menuItemsWithDivider}
            >
              {typeof item === "object" ? item.label : item}
            </MenuItem>
          );
        })}
      </MaterialSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

Select.propTypes = {
  selectItems: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  menuItemsWithDivider: PropTypes.bool,
  dropdownItemClasses: PropTypes.shape({
    root: PropTypes.string,
    divider: PropTypes.string,
    gutters: PropTypes.string,
  }),
  selectClasses: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    nativeInput: PropTypes.string,
    filled_variant: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  menuProps: PropTypes.shape({
    anchorOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(["top", "bottom"]),
      horizontal: PropTypes.oneOf(["left", "right"]),
    }),
    transformOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(["top", "bottom"]),
      horizontal: PropTypes.oneOf(["left", "right"]),
    }),
    getContentAnchorEl: PropTypes.func,
  }),
  name: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  className: PropTypes.string,
};

Select.defaultProps = {
  variant: "filled",
  menuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
};

export default Select;
