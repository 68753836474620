import React, { useReducer, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { useStyles } from "./Document.style";
import PDFActions from "../PDFActions/PDFActions";
import Grid from "../../../common/Grid/Grid";
import Checkbox from "../../../common/Checkbox/Checkbox";
import Edit from "../../../common/Icons/Edit";
import Trashcan from "../../../common/Icons/Trashcan";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import Typography from "../../../common/Typography/Typography";
import { useDocuments } from "../../../context/CMS/DocumentsContext/DocumentsContext";
import { Document as PDFDocument } from "../../../classes/Document";
import { convertBytestoKB } from "../../../services/documents";
import Button from "../../../common/Button/Button";
import TextField from "../../../common/TextField/TextField";
import Box from "../../../common/Box/Box";
import CheckIcon from "../../../common/Icons/Check";
import CloseIcon from "../../../common/Icons/Close";

const BLACK = "#000000";
const GRAY = "#808080";

const Document = ({ document, isDiy }) => {
  const classes = useStyles();
  const [actionsOpened, setActionsOpened] = useState(false);
  const [showInlineNameEdit, setShowInlineNameEdit] = useState(false);
  const [newDocumentName, setNewDocumentName] = useState(
    document.removeExtensionName()
  );
  const [fileNameUpdated, setFileNameUpdated] = useState();

  const {
    removeDocument,
    handleCheckboxChange,
    selectedDocuments,
    haveBulkActions,
    showEditActions,
    updateFileName,
    setDocumentInfo,
    editFileNameInline,
  } = useDocuments();

  const handleRemove = async () => {
    if (confirm(labels.PDF_ACTION_LABELS.REMOVAL_CONFIRMATION)) {
      await removeDocument(document);
      setActionsOpened(false);
    }
  };

  const handleUpdateDocumentName = async (document, fileName) => {
    if (document.isNotValidFileName(fileName)) {
      setDocumentInfo({
        open: true,
        message: "Invalid file name",
        variant: "error center_in_screen",
      });
      return;
    }

    const updated = await updateFileName({
      documentId: document.id,
      contributionId: document.contributionId,
      fileName,
      contributionType: document.contributionType,
    });

    if (updated) setShowInlineNameEdit(false);
    return;
  };

  return (
    <>
      <PopUpDialog
        content={
          <PDFActions
            document={document}
            afterAction={() => setActionsOpened(false)}
          />
        }
        openState={actionsOpened}
        onClose={() => setActionsOpened(false)}
        className={classes.pdf_actions_pop_up}
        contentClass={classes.content}
        titleClass={classes.no_title}
        closeButtonColor={BLACK}
        closeButtonClass={classes.close_button}
      />
      <Grid
        container
        spacing={2}
        row
        className={classes.container}
        rowSpacing={200}
      >
        {haveBulkActions && (
          <Grid item className={classes.checkbox_container}>
            <Checkbox
              value={document.id}
              checked={selectedDocuments.includes(document.id)}
              onChange={() => handleCheckboxChange(document.id)}
              checkboxColor={GRAY}
            />
          </Grid>
        )}
        <Grid item className={classes.thumbnail_container}>
          <img src={document.thumbnailUrl} height={"100%"} />
        </Grid>
        <Grid item className={classes.filename_container}>
          {editFileNameInline && showInlineNameEdit ? (
            <Box className={classes.file_name_container}>
              <TextField
                useDefaultClasses={false}
                value={newDocumentName}
                onChange={(e) => setNewDocumentName(e.target.value)}
                useNativeError
                error={fileNameUpdated === false}
                helperText={labels.FILE_NAME_TEXT}
                className={classes.edit_name_text_field}
              />
              <ButtonIcon
                onClick={() =>
                  handleUpdateDocumentName(document, newDocumentName)
                }
                withDefaultStyles
                children={<CheckIcon width={16} height={16} />}
                className={classes.edit_name_button}
              />
              <ButtonIcon
                onClick={() => setShowInlineNameEdit(false)}
                withDefaultStyles
                children={<CloseIcon width={16} height={16} />}
                className={classes.edit_name_button}
              />
            </Box>
          ) : (
            <Box className={classes.file_name_container}>
              <Typography level={"t4_text_4"} className={classes.filename}>
                {fileNameUpdated
                  ? newDocumentName + "." + document.extensionName
                  : document.name}
              </Typography>
              {editFileNameInline && !showInlineNameEdit && (
                <ButtonIcon
                  onClick={() => {
                    setShowInlineNameEdit(true), setFileNameUpdated();
                  }}
                  withDefaultStyles
                  children={<Edit fillColor={BLACK} width={16} height={16} />}
                  className={classes.edit_name_button}
                />
              )}
            </Box>
          )}
          <Typography
            level={"t4_text_4"}
            color={"lightGray"}
            className={classes.size}
          >
            {convertBytestoKB(document.documentSize)} kb
          </Typography>
        </Grid>
        <Grid item className={classes.actions}>
          {showEditActions && (
            <>
              {!haveBulkActions || isDiy ? (
                <Button
                  onClick={() => setActionsOpened(true)}
                  level={"legal_notice_button"}
                  children={<Typography bold>{labels.PDF_ACTIONS}</Typography>}
                  className={classes.edit_button_text}
                />
              ) : (
                <ButtonIcon
                  onClick={() => setActionsOpened(true)}
                  withDefaultStyles
                  children={<Edit fillColor={BLACK} width={24} height={24} />}
                  className={classes.edit_button}
                />
              )}
            </>
          )}
          <ButtonIcon
            onClick={handleRemove}
            withDefaultStyles
            children={<Trashcan fillColor={BLACK} width={24} height={24} />}
            className={classes.edit_button}
          />
        </Grid>
      </Grid>
    </>
  );
};

Document.propTypes = {
  document: PropTypes.instanceOf(PDFDocument).isRequired,
};

export default Document;
