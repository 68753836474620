import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    header_container: {
      [theme.breakpoints.down(900)]: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    title_container: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(900)]: {
        width: "85%",
      },
    },
    title: {
      lineHeight: `${theme.spacing(4)}px`,
      [theme.breakpoints.down(961)]: {
        fontSize: 17,
        lineHeight: `${theme.spacing(3)}px`,
      },
    },
    general_container: {
      height: theme.spacing(54),
      backgroundColor: theme.palette.grayVariation.grayForBackground,
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(4),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(3),
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",

      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
      },
    },
    general_container_augmented_height: {
      [theme.breakpoints.down(961)]: {
        height: theme.spacing(74),
      },
    },
    towns_by_county_container: {
      backgroundColor: theme.palette.miscellaneous.white,
      borderRadius: theme.spacing(1),
      height: "90%",
      width: "50%",
      overflowY: "scroll",
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
      },
    },
    towns_by_county_container_augmented_height: {
      [theme.breakpoints.down(961)]: {
        height: "50%",
      },
    },
    search_container: {
      width: "40%",
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
        marginTop: theme.spacing(3),
        maxHeight: theme.spacing(30),
      },
    },
    subtitle_container: {
      width: "100%",
    },
    state_tabs_container: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    unselected_state_tab: {
      height: theme.spacing(4),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.light.gray,
      borderRadius: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      "& :hover": {
        cursor: "pointer",
      },
    },
    selected_state_tab: {
      backgroundColor: theme.palette.themeColor.secondary,
    },
    unselected_state_tab_highlight: {
      height: theme.spacing(6.5),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      backgroundColor: theme.palette.miscellaneous.white,
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0px 0px`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selected_state_tab_highlight: {
      backgroundColor: theme.palette.grayVariation.grayForBackground,
    },
    towns_by_county_container_mobile: {
      display: "block",
    },
    shopping_cart_button_icon: {
      width: "auto",
      height: "auto",
      "&.MuiIconButton-root": {
        height: "auto",
        width: "auto",
      },
    },
    exemption_disclaimer_container_desktop: {
      marginTop: `-${theme.spacing(2)}px`,
      marginBottom: theme.spacing(1.5),
      lineHeight: "12px",
    },
    exemption_disclaimer_container_mobile: {
      marginTop: `-${theme.spacing(1)}px`,
      marginBottom: theme.spacing(1),
      lineHeight: "12px",
    },
    state_label: {
      [theme.breakpoints.down(450)]: {
        lineHeight: "14px",
      },
    },
  };
});

export { useStyles };
