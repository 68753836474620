import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    checkbox_container: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      color: palette.grayVariation.grayForCredits + " !important",
    },
    placeholder_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: `${theme.spacing(2)}px 0px`,
    },
    placeholder: {
      maxWidth: "400px",
    },
  };
});

export { useStyles };
