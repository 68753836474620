import React, { useEffect, useState } from "react";
import { useStyles } from "./IncludeEnewsListRow.style";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";
import { useDiy, withDiyContextProvider } from "../../../../context/DiyContext";
import Checkbox from "../../../../common/Checkbox/Checkbox";
import ButtonIcon from "../../../../common/Button/ButtonIcon";
import CloseIcon from "../../../../common/Icons/Close";

const IncludeEnewsListRow = ({
  town,
  enewsDatesSelected,
  itemQuantity,
  isAdvertiser,
  advertiserTownsIds,
}) => {
  const classes = useStyles();
  const { updateEnewsTowns } = useDiy();
  const [isSelected, setIsSelected] = useState(town?.enews_selected);

  useEffect(() => {
    updateEnewsTowns(town, isSelected);
  }, [isSelected]);

  return (
    <>
      <div className={classes.white_row}>
        <div className={classes.town_name_container}>
          <Typography level="t1_text_1" bold>
            {town.label}
            {itemQuantity ? ` (x ${itemQuantity})` : ""}
          </Typography>
        </div>
        <div className={classes.actions_container}>
          <Checkbox
            className={classes.checkbox}
            onChange={() => setIsSelected(!isSelected)}
            checkboxColor={"#000000"}
            checked={isSelected}
            dataAttribute="town-enews"
          />
          {/* Component use to future work */}
          {/* <ButtonIcon
            onClick={() => onRemoveTown(town.id)}
            withDefaultStyles
            className={classes.delete_icon}
          >
            <CloseIcon />
          </ButtonIcon> */}
        </div>
      </div>

      {enewsDatesSelected?.map((date, index) => (
        <div key={index} className={classes.date_row}>
          <div className={classes.date_title}>
            <Typography>{labels.ENEWS_DATE_TITLE}</Typography>
          </div>
          <div className={classes.date_info_row}>
            <div className={classes.date_letter}>
              <Typography>{date.date}</Typography>
            </div>
            <div className={classes.town_price_container}>
              <Typography level="t1_text_1">
                {labels.DOLLAR_SIGN}
                {isAdvertiser &&
                advertiserTownsIds?.includes(town.id) &&
                index == 0
                  ? 0.0
                  : town.enews_price}
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default withDiyContextProvider(IncludeEnewsListRow);
