export class Document {
  constructor(
    id,
    contributionId,
    contributionType,
    documentSize,
    documentUrl,
    thumbnailUrl,
    name,
    availablePages,
    thumbnailNumberImage,
    addToImagesCarousel,
    captions,
    credits,
    extension_name
  ) {
    this.id = id;
    this.contributionId = contributionId;
    this.contributionType = contributionType;
    this.documentSize = documentSize;
    this.documentUrl = documentUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.name = name;
    this.availablePages = availablePages;
    this.thumbnailNumberImage = thumbnailNumberImage;
    this.addToImagesCarousel = addToImagesCarousel;
    this.captions = captions;
    this.credits = credits;
    this.extensionName = extension_name;
  }

  sanitizeFileName() {
    return this.name.replace(/[^a-zA-Z0-9_-]/g, "_");
  }

  isNotValidFileName(fileName) {
    const name = fileName || this.name;
    return name.match(/[^a-zA-Z0-9_-]/g)?.length;
  }

  removeExtensionName() {
    return this.name.replace(/\.[^/.]+$/, "");
  }
}
