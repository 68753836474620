import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./Pagination.style";
import { Pagination as MaterialPagination } from "@material-ui/lab";

const Pagination = ({
  count,
  size,
  page,
  variant,
  shape,
  onChange,
  className,
  color,
  bold,
  siblingCount,
}) => {
  const classes = useStyles({ color, bold });
  return (
    <>
      <MaterialPagination
        count={count}
        size={size}
        page={page}
        variant={variant}
        shape={shape}
        onChange={onChange}
        className={className || classes.pagination}
        siblingCount={siblingCount}
      />
    </>
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  size: PropTypes.number,
  page: PropTypes.number,
  variant: PropTypes.string,
  shape: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"]),
  bold: PropTypes.bool,
};

Pagination.defaultProps = {
  bold: true,
};

export default Pagination;
