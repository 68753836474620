import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    pagination: {
      "& .Mui-selected": {
        backgroundColor: (props) => props?.color ? `${theme.palette.cms[props.color].main} !important` : "unset !important",
        color: (props) => props?.color ? theme.palette.cms[props.color].textContrast : "#F98C10",
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        fontWeight: (props) => props?.bold ? "bold" : 'normal',
      },
    },
  };
});

export { useStyles };
