import React, { useEffect } from "react";
import clsx from "clsx";
import { object } from "prop-types";
import { buildAdTargets } from "../../utils/googleTagManagerHelper";
import Carousel from "../../common/Carousel/Carousel";
import Typography from "../../common/Typography/Typography";
import MastheadLayout from "../../components/Masterhead/Layouts/Layouts";
import Footer from "../../components/Footer/Footer";
import RightBlocks from "../../components/RightBlocks/RightBlocks";
import { useTown, withTownContextProvider } from "../../context/TownContext";
import { useStyles } from "./Pages.style";
import { INHERITED_CLASS_FOR_CKEDITOR } from "../../common/Theme/customCssNames";

const MIN_ITEMS_TO_SHOW = 1;

const Pages = ({ page, masthead_props, footer_props, town, media_content }) => {
  const classes = useStyles();
  const { setTown } = useTown();

  useEffect(() => {
    if (town) {
      setTown(town);
    }
  }, [town]);

  useEffect(() => {
    let scripts = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(page?.body, "text/html");

    const scriptTags = doc.querySelectorAll("body script");

    scriptTags.forEach((script) => {
      if (script) {
        const newScript = document.createElement("script");

        for (let i = 0; i < script.attributes.length; i++) {
          const attribute = script.attributes[i];
          newScript.setAttribute(attribute.name, attribute.value);
        }
        scripts.push(newScript);
        document.body.appendChild(newScript);
      }

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    });
  }, [page]);

  return (
    <>
      <MastheadLayout {...masthead_props} />

      <div
        className={clsx(classes.container, !town && classes.masthead_padding)}
      >
        <div className={classes.page}>
          <Typography level="h2">{page?.name}</Typography>
          <div className={classes.carousel_container}>
            {media_content?.length >= MIN_ITEMS_TO_SHOW && (
              <Carousel items={media_content} />
            )}
          </div>
          <div
            className={clsx(INHERITED_CLASS_FOR_CKEDITOR, classes.body)}
            dangerouslySetInnerHTML={{ __html: page?.body }}
          />
        </div>

        {town && (
          <div className={classes.right_blocks}>
            <RightBlocks town={town} adTargets={buildAdTargets(town, null)} />
          </div>
        )}
      </div>

      <Footer {...footer_props} />
    </>
  );
};

Pages.propTypes = {
  page: object,
  masthead_props: object,
  footer_props: object,
  town: object,
};

export default withTownContextProvider(Pages);
