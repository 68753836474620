import React, { useReducer, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../../../common/Button/Button";
import Snackbar from "../../../common/Snackbar/Snackbar";
import labels from "../../../config/labels";
import {
  snackbarInitialState,
  snackbarReducer,
} from "../../../reducers/snackbars/snackbarsReducers";
import useSubmitableContentForm from "../useSubmitableContentForm";
import ButtonShoppingCart from "../../../common/Icons/ButtonShoppingCart";
import {
  CONTENT_ID,
  BUSINESS_DIRECTORY_LISTING,
  CANDIDATE_STATEMENTS,
  CLASSIFIED,
  COLUMN,
  DIY_FREE_CONTENT_FORMS,
  EVENT,
  LETTERS_TO_THE_EDITOR,
  MANDATORY_FIELDS_FOR_ARTICLE_UPDATE,
  MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING_UPDATE,
  MANDATORY_FIELDS_FOR_CLASSIFIED_UPDATE,
  MANDATORY_FIELDS_FOR_COLUMN,
  MANDATORY_FIELDS_FOR_EVENT_UPDATE_FORM,
  MANDATORY_FIELDS_FOR_MILESTONE_UPDATE,
  MANDATORY_FIELDS_FOR_OBITUARY_UPDATE,
  MANDATORY_FIELDS_FOR_REAL_ESTATE_UPDATE_FORM,
  MILESTONE,
  NEWS,
  OBITUARY,
  REAL_ESTATE_LISTING,
  LEGAL_NOTICE,
} from "../diyConstants";
import { useStyles } from "./DiyActions.style";
import withConfig from "../../../utils/withConfig";
import { replaceIdInUrl } from "../../../utils/helper";

const DiyActions = ({
  setLoadingCheckout,
  isEditForm,
  formType,
  setOpenMobileShoppingSummary,
  isRadioButtonChecked,
  townsSelected,
  containerClassName,
  contentId,
  legalNoticeTermsChecked,
}) => {
  const [loading, setLoading] = useState({
    type: "",
    isLoading: false,
  });
  const [snackbarProps, setSnackbarProps] = useReducer(
    snackbarReducer,
    snackbarInitialState
  );
  const classes = useStyles();

  const { submitForm, submitDraftForm, setProperty, updateContentForm } =
    useSubmitableContentForm();

  const shouldShowAction = (type) => {
    if (formType == "freelance") {
      return false;
    }
    switch (type) {
      case "draft":
        return !isEditForm;
      default:
        break;
    }
  };

  const saveDraft = async () => {
    try {
      setLoading({
        type: "draft",
        isLoading: true,
      });
      const savedDraftResponse = await submitDraftForm();
      if (savedDraftResponse?.draft_saved === 200) {
        setProperty(CONTENT_ID, savedDraftResponse?.id);
        setSnackbarProps({
          message: labels.YOUR_DRAFT_WAS_SAVED,
          variant: "success center_in_screen",
          open: true,
        });

        setLoading({
          type: "draft",
          isLoading: false,
        });
      } else {
        setSnackbarProps({
          message: labels.ERROR_SAVING_DRAFT,
          variant: "error center_in_screen",
          open: true,
        });
        setLoading({
          type: "draft",
          isLoading: false,
        });
      }
    } catch (error) {
      setSnackbarProps({
        message: labels.ERROR_SAVING_DRAFT,
        variant: "error center_in_screen",
        open: true,
      });
      setLoading({
        type: "draft",
        isLoading: false,
      });
    }
  };

  const handlePreview = async () => {
    const response = await submitForm(true);

    if (response?.errors?.length > 0) {
      setOpenMobileShoppingSummary(false);
    } else {
      setLoading({
        type: "preview",
        isLoading: true,
      });

      setTimeout(() => {
        setLoading({
          type: "preview",
          isLoading: false,
        });
      }, 3000);
    }
  };

  const updateContent = async () => {
    let url = "";
    let mandatoryFields = [];

    switch (formType) {
      case REAL_ESTATE_LISTING:
        url = withConfig("UPDATE_REAL_ESTATE");
        mandatoryFields = MANDATORY_FIELDS_FOR_REAL_ESTATE_UPDATE_FORM;
        break;
      case EVENT:
        url = withConfig("UPDATE_EVENT");
        mandatoryFields = MANDATORY_FIELDS_FOR_EVENT_UPDATE_FORM;
        break;
      case NEWS:
      case CANDIDATE_STATEMENTS:
      case LETTERS_TO_THE_EDITOR:
        url = withConfig("UPDATE_ARTICLE");
        mandatoryFields = MANDATORY_FIELDS_FOR_ARTICLE_UPDATE;
        break;
      case CLASSIFIED:
        url = withConfig("UPDATE_CLASSIFIED");
        mandatoryFields = MANDATORY_FIELDS_FOR_CLASSIFIED_UPDATE;
        break;
      case BUSINESS_DIRECTORY_LISTING:
        url = withConfig("UPDATE_DIRECTORY");
        mandatoryFields =
          MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING_UPDATE;
        break;
      case MILESTONE:
        url = withConfig("UPDATE_MILESTONE");
        mandatoryFields = MANDATORY_FIELDS_FOR_MILESTONE_UPDATE;
        break;
      case OBITUARY:
        url = withConfig("UPDATE_OBITUARY");
        mandatoryFields = MANDATORY_FIELDS_FOR_OBITUARY_UPDATE;
        break;
      case COLUMN:
        url = withConfig("UPDATE_ARTICLE");
        mandatoryFields = MANDATORY_FIELDS_FOR_COLUMN;
        break;
      default:
        break;
    }

    const response = await updateContentForm(
      replaceIdInUrl(url, contentId),
      mandatoryFields
    );

    return response;
  };

  const handleAlert = () => {
    if (formType === LEGAL_NOTICE && !legalNoticeTermsChecked) {
      return true;
    } else if (!DIY_FREE_CONTENT_FORMS.includes(formType) && !isRadioButtonChecked) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    let response;
    setLoading({
      type: "submit",
      isLoading: true,
    });

    if (isEditForm) {
      response = await updateContent();
    } else {
      if (handleAlert()) {
        if (townsSelected.length > 0) {
          setLoading({
            type: "submit",
            isLoading: false,
          });
          alert(labels.MUST_AGREE_TO_TAPINTO_TERMS);
          return;
        }
        if (townsSelected.length === 0) {
          setLoading({
            type: "submit",
            isLoading: false,
          });
          alert(labels.MUST_AGREE_TO_TAPINTO_TERMS_WITH_TOWNS);
          return;
        }
      }
      setLoadingCheckout(true);
      response = await submitForm();
    }

    if (response?.errors?.length > 0) {
      setLoadingCheckout(false);
      setLoading({
        type: "submit",
        isLoading: false,
      });
      setOpenMobileShoppingSummary(false);
      window.scrollTo(0, 0);
    }
  };

  const CheckoutButtonLabel = () => {
    if (DIY_FREE_CONTENT_FORMS.includes(formType) || isEditForm) {
      return labels.SUBMIT;
    } else {
      return (
        <>
          <ButtonShoppingCart className={classes.shopping_cart_icon} />
          {labels.SUBMIT_AND_CHECKOUT}
        </>
      );
    }
  };

  return (
    <div
      className={clsx(
        containerClassName && containerClassName,
        classes.buttons_container
      )}
    >
      {shouldShowAction("draft") && (
        <>
          <Button
            level="simple_button"
            id="save-draft-diy-button"
            color="primary"
            onClick={saveDraft}
            classes={{
              root: clsx(classes.save_draft_button, classes.options_button),
            }}
            disabled={loading.type === "draft" && loading.isLoading}
            ariaLabel="Save Draft"
          >
            {loading.type === "draft" && loading.isLoading
              ? labels.SAVING_DRAFTS
              : labels.SAVE_DRAFT}
          </Button>
          <Snackbar
            onClose={() => setSnackbarProps(snackbarInitialState)}
            open={snackbarProps.open}
            message={snackbarProps.message}
            variant={snackbarProps.variant}
          />
        </>
      )}

      <Button
        level="simple_button"
        color="primary"
        variant="text"
        classes={{
          root: clsx(
            classes.preview_button,
            classes.options_button,
            !shouldShowAction("draft") && classes.full_width
          ),
        }}
        disabled={loading.type === "preview" && loading.isLoading}
        onClick={handlePreview}
        ariaLabel="Preview Content"
      >
        {loading.type === "preview" && loading.isLoading
          ? labels.OPENING_PREVIEW
          : labels.PREVIEW_CONTENT}
      </Button>

      <Button
        id="submit-diy-button"
        level="simple_button"
        color="primary"
        disabled={loading.type === "submit" && loading.isLoading}
        onClick={handleSubmit}
        classes={{ root: classes.checkout_button }}
        ariaLabel="Submit and Checkout"
      >
        <CheckoutButtonLabel />
      </Button>
    </div>
  );
};

DiyActions.propTypes = {
  setLoadingCheckout: PropTypes.func,
  isEditForm: PropTypes.bool,
  formType: PropTypes.string,
  setOpenMobileShoppingSummary: PropTypes.func,
  isRadioButtonChecked: PropTypes.bool,
  townsSelected: PropTypes.array,
  containerClassName: PropTypes.string,
  contentId: PropTypes.number,
};

export default DiyActions;
