import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ShoppingSummary.style";
import {
  updateSummaryComponentListener,
  setOpenMobileShoppingCart,
  updateFacebookOptionListener,
  updateAdsFreeOptionListener,
  updateIncludeInEnewsListener,
  updateEnewsDatesListener,
} from "../../../utils/listeners";
import IconBadge from "../../../common/IconBadge/IconBadge";
import ShoppingCartIcon from "../../../common/Icons/ShoppingCartIcon";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { getCurrentScrollPosition } from "../../../hooks/useScrollPosition";
import clsx from "clsx";
import {
  LETTERS_TO_THE_EDITOR,
  SELECTED_TOWNS,
  COLUMN,
  MILESTONE,
  GRAY,
  POST_ON_FACEBOOK,
  AD_FREE,
  INCLUDE_IN_ENEWS,
  ENEWS_DATES_ARRAY,
  ADVERTISER_TOWNS_IDS,
  IS_ADVERTISER,
} from "../diyConstants";
import cache from "../../../utils/cache";
import ShoppingCart from "./ShoppingCart/ShoppingCart";

const ShoppingSummary = ({
  isMobileView,
  currentScrollPosition,
  form_type,
  is_edit_form,
  item_count_for_event_draft,
  town,
  is_advertiser,
  advertiser_towns_ids,
}) => {
  const classes = useStyles();
  const [townsSelected, setTownSelected] = useState(
    isMobileView ? cache.get(SELECTED_TOWNS) : []
  );
  const [openMobileShoppingSummary, setOpenMobileShoppingSummary] =
    useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [isFacebookOptionSelected, setIsFacebookOptionSelected] = useState(
    cache.get(POST_ON_FACEBOOK) === 1
  );
  const [isAdsFreeOptionSelected, setIsAdsFreeOptionSelected] = useState(
    cache.get(AD_FREE) === 1
  );

  const [isIncludeInEnewsSelected, setIncludeInEnewsSelected] = useState(
    cache.get(INCLUDE_IN_ENEWS) === 1
  );
  const [enewsDatesSelected, setEnewsDatesSelected] = useState(
    cache.get(ENEWS_DATES_ARRAY)
  );

  const position = getCurrentScrollPosition();

  const [showShoppingCart, setShowShoppingCart] = useState(
    ![LETTERS_TO_THE_EDITOR, COLUMN].includes(form_type) ||
      (isFacebookOptionSelected && form_type === MILESTONE)
  );

  setOpenMobileShoppingCart(setOpenMobileShoppingSummary);
  updateSummaryComponentListener(setTownSelected);

  useEffect(() => {
    if (form_type === MILESTONE) {
      setShowShoppingCart(isFacebookOptionSelected);
    }
  }, [isFacebookOptionSelected]);

  useEffect(() => {
    if (form_type === MILESTONE) {
      setShowShoppingCart(isAdsFreeOptionSelected);
    }
  }, [isAdsFreeOptionSelected]);

  useEffect(() => {
    if (form_type === MILESTONE) {
      setShowShoppingCart(isIncludeInEnewsSelected);
    }
  }, [isIncludeInEnewsSelected]);

  useEffect(() => {
    if (advertiser_towns_ids?.length) {
      cache.set(ADVERTISER_TOWNS_IDS, advertiser_towns_ids);
    }
    if (is_advertiser) {
      cache.set(IS_ADVERTISER, is_advertiser);
    }
  }, [advertiser_towns_ids, is_advertiser]);

  // This runs third when clicking Facebook checkbox
  updateFacebookOptionListener(setIsFacebookOptionSelected);
  updateIncludeInEnewsListener(setIncludeInEnewsSelected);
  updateEnewsDatesListener(setEnewsDatesSelected);
  updateAdsFreeOptionListener(setIsAdsFreeOptionSelected);

  const getShoppingCartBadgeContent = () => {
    if (townsSelected.length <= 0) {
      return "0";
    } else {
      return townsSelected.length;
    }
  };

  const handleRemoveTown = (townId) => {
    setTownSelected((prevTowns) => {
      const updatedTowns = prevTowns.filter((town) => town.id !== townId);
      return updatedTowns;
    });
  };
  return (
    <>
      {showShoppingCart && (
        <>
          {isMobileView ? (
            <>
              <ButtonIcon
                onClick={() => setOpenMobileShoppingSummary(true)}
                className={clsx(
                  position > currentScrollPosition &&
                    classes.sticky_shopping_cart,
                  classes.shopping_cart_button_icon
                )}
                ariaLabel="Shopping Summary"
              >
                <IconBadge
                  icon={<ShoppingCartIcon />}
                  badgeContent={getShoppingCartBadgeContent()}
                />
              </ButtonIcon>
              <PopUpDialog
                onClose={() => setOpenMobileShoppingSummary(false)}
                openState={openMobileShoppingSummary}
                title={""}
                content={
                  <ShoppingCart
                    town={town}
                    itemCountForEventDraft={item_count_for_event_draft}
                    isMobileView={isMobileView}
                    townsSelected={townsSelected}
                    formType={form_type}
                    loadingCheckout={loadingCheckout}
                    setLoadingCheckout={setLoadingCheckout}
                    showFacebookBlock={isFacebookOptionSelected}
                    showAdsFreeBlock={isAdsFreeOptionSelected}
                    showIncludeInEnews={isIncludeInEnewsSelected}
                    enewsDatesSelected={enewsDatesSelected}
                    isEditForm={is_edit_form}
                    setOpenMobileShoppingCart={setOpenMobileShoppingCart}
                    onRemoveTown={handleRemoveTown}
                    advertiser={is_advertiser}
                    advertiserTownIds={advertiser_towns_ids}
                  />
                }
                titleClass={classes.dialog_title}
                closeButtonColor={GRAY}
                closeButtonClass={classes.dialog_close_button}
              />
            </>
          ) : (
            <ShoppingCart
              town={town}
              itemCountForEventDraft={item_count_for_event_draft}
              isMobileView={isMobileView}
              townsSelected={townsSelected}
              formType={form_type}
              loadingCheckout={loadingCheckout}
              setLoadingCheckout={setLoadingCheckout}
              showFacebookBlock={isFacebookOptionSelected}
              showAdsFreeBlock={isAdsFreeOptionSelected}
              showIncludeInEnews={isIncludeInEnewsSelected}
              enewsDatesSelected={enewsDatesSelected}
              isEditForm={is_edit_form}
              setOpenMobileShoppingCart={setOpenMobileShoppingCart}
              onRemoveTown={handleRemoveTown}
              advertiser={is_advertiser}
              advertiserTownIds={advertiser_towns_ids}
            />
          )}
        </>
      )}
    </>
  );
};

ShoppingSummary.propTypes = {
  isMobileView: PropTypes.bool,
  currentScrollPosition: PropTypes.number,
  form_type: PropTypes.string,
  is_edit_form: PropTypes.bool,
  item_count_for_event_draft: PropTypes.number,
};

ShoppingSummary.defaultProps = {
  isMobileView: false,
};

export default ShoppingSummary;
