import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ArticleHeader.style";
import Typography from "../../common/Typography/Typography";
import Link from "../../common/Link/Link";
import Grid from "../../common/Grid/Grid";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../hooks/useMediaQuery";
import Edit from "../Actions/Edit";
import clsx from "clsx";
import labels from "../../config/labels";
import Sponsor from "../Sponsor/Sponsor";

const ArticleHeader = ({
  title,
  subheading,
  section,
  subsection_slug,
  subsection_name,
  section_link,
  category_name,
  is_advertisement,
  can_edit,
  article_edit_url,
  is_column_article,
  sponsor,
}) => {
  const classes = useStyles();

  const [subsectionLink, setSubsectionLink] = useState();
  const [subsectionName, setSubsectionName] = useState();

  useEffect(() => {
    if (subsection_slug) {
      setSubsectionLink(section_link + "/" + subsection_slug);
    }
    if (subsection_name){
      setSubsectionName(
        subsection_name.charAt(0).toUpperCase() + subsection_name.slice(1)
      );
    }
  }, [subsection_slug, subsection_name]);

  const SponsoredTag = () => {
    if (is_advertisement) {
      return (
        <div className={classes.sponsored_content}>
          <Typography level="paragraph_2" color={"gray"}>
            {labels.SPONSORED_CONTENT}
          </Typography>
        </div>
      );
    } else {
      return "";
    }
  };

  const sectionAndNotCategory = () => {
    return section && !category_name;
  };

  const categoryAndNotSection = () => {
    return !section && category_name;
  };

  const sectionAndCategory = () => {
    return section && category_name;
  };

  return (
    <>
      <Grid className={classes.border_top_bottom} container>
        {sectionAndCategory() && (
          <>
            <Grid item xs={6}>
              <Link
                className={classes.heading_h2}
                url={
                  subsectionLink
                    ? subsectionLink
                    : section_link
                    ? section_link
                    : "www.tapinto.net"
                }
                color={"tapintoGreen"}
                level={"h2_header_2"}
                bold
              >
                {subsectionName
                  ? subsectionName
                  : is_column_article
                  ? labels.COLUMN_TWO_DOTS + section
                  : section}
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={classes.heading_category}
                color={"black"}
                level={"h2_header_2"}
                bold
              >
                {subsectionName ? subsectionName : category_name}
              </Typography>
            </Grid>
          </>
        )}
        {sectionAndNotCategory() && (
          <div>
            <Link
              className={classes.heading_h2}
              url={
                subsectionLink
                  ? subsectionLink
                  : section_link
                  ? section_link
                  : "www.tapinto.net"
              }
              color={"tapintoGreen"}
              level={"h2_header_2"}
              bold
            >
              {subsectionName
                ? subsectionName
                : is_column_article
                ? labels.COLUMN_TWO_DOTS + section
                : section}
            </Link>
          </div>
        )}
        {categoryAndNotSection() && (
          <>
            <Grid item xs={6}>
              {/* Leaving this empty grid to align category name to the right */}
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={classes.heading_category}
                color={"black"}
                level={"h2_header_2"}
                bold
              >
                {subsectionName ? subsectionName : category_name}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {can_edit && (
        <Grid item xs={12} classes={{ root: classes.edit_container }}>
          <Edit editUrl={article_edit_url} />
        </Grid>
      )}
      <SponsoredTag />
      {sponsor && (
        <Grid item xs={12} classes={{ root: classes.sponsor_container }}>
          <Sponsor sponsor_info={sponsor} className={classes.sponsor} />
        </Grid>
      )}

      <div className={classes.container}>
        {screenWidthIsHigherThan(402) && (
          <div
            className={clsx(
              classes.title_and_heading_container,
              can_edit ? classes.splitted : ""
            )}
          >
            <Typography
              className={classes.container_title}
              level="h1_header_1"
              bold
            >
              {title}
            </Typography>
            <Typography
              className={classes.subheading_article}
              level="h2_header_2"
              color="gray"
              bold
            >
              {subheading}
            </Typography>
          </div>
        )}
        {screenWidthIsLowerThan(402) && (
          <div
            className={clsx(
              classes.title_and_heading_container,
              can_edit ? classes.splitted : ""
            )}
          >
            <Typography
              className={classes.container_title_mobile}
              level="h1_header_1_mobile"
              bold
            >
              {title}
            </Typography>
            <Typography
              className={classes.subheading_article_mobile}
              level="h2_header_2_mobile"
              color="gray"
              bold
            >
              {subheading}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

ArticleHeader.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  section: PropTypes.string,
  section_link: PropTypes.string,
  category_name: PropTypes.string,
  is_advertisement: PropTypes.bool,
  can_edit: PropTypes.bool.isRequired,
  article_edit_url: PropTypes.string.isRequired,
  is_column_article: PropTypes.bool,
};

export default ArticleHeader;
