import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    header_container: {
      height: "auto",
      borderBottom: `4px ${theme.palette.dark.gray} solid`,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
      "& div.MuiInputAdornment-positionEnd": {
        marginLeft: "0px !important",
      },
      "& input.MuiInputBase-input": {
        paddingRight: "0px !important",
      },
      [theme.breakpoints.down(960)]: {
        paddingBottom: theme.spacing(3),
      },
    },
    header_title_container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap"
    },
    left_container: {
      width: "45%",
      "& h5": {
        marginBottom: `0px !important`,
      },
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    right_container: {
      width: "45%",
      paddingTop: theme.spacing(0.25),
      [theme.breakpoints.down(960)]: {
        width: "100%",
        paddingTop: theme.spacing(1.75),
      },
    },
    dropdown_label: {
      marginBottom: `${theme.spacing(2.5)}px !important`,
    },
    obituary_header_button: {
      borderRadius: "0px !important",
      width: theme.spacing(20.6),
      height: theme.spacing(4),
      backgroundColor: `${theme.palette.grey.A400} !important`,
      [theme.breakpoints.down(960)]: {
        minWidth: theme.spacing(18),
        height: theme.spacing(2.6),
      },
      marginBottom: `${theme.spacing(0.0625)}rem!important`,
    },
    button_label: {
      color: theme.palette.common.white,
      textTransform: "capitalize",
      fontFamily: "Montserrat, sans-serif",
      fontSize: `${theme.spacing(1.8)}px`,
      textWrap: "nowrap",
      [theme.breakpoints.down(960)]: {
        fontSize: `${theme.spacing(1.7)}px`,
      },
    },
  };
});

export { useStyles };
