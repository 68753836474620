import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cache from "../utils/cache";
import {
  SELECTED_TOWNS,
  FACEBOOK_TOWN_IDS,
  POST_ON_FACEBOOK,
  AD_FREE,
  ADS_FREE_TOWN_IDS,
  MAIN_TOWN_IDS,
  ORGANIZATION_TYPES,
  INCLUDE_IN_ENEWS,
  ENEWS_TOWN_IDS,
  ENEWS_DATES_ARRAY,
} from "../components/Diy/diyConstants";
import { cloneWithoutReferences } from "../utils/helper";
import { removeTownListener } from "../utils/listeners";
import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

const DiyContext = React.createContext({});

const DiyContextProvider = ({ children }) => {
  const [selectedTownsDiy, setSelectedTownsDiy] = useState([]);
  const [town, setTown] = useState();
  const [formType, setFormType] = useState();
  const [contributionType, setContributionType] = useState();
  const [adFlag, setAdFlag] = useState(false);
  const [optionalPrices, setOptionalPrices] = useState("");
  const [selectedEnewsDatesForDatabase, setSelectedEnewsDatesForDatabase] =
    useState([
      {
        id: null,
        date: new Date(
          new Date().setDate(new Date().getDate() + 2)
        ).toLocaleDateString("en-US"),
      },
    ]);

  const getSelectedTowns = () => {
    return cache.get(SELECTED_TOWNS);
  };
  const showOrHideFacebookBlock = () => {
    const showOrHideFacebookBlockEvent = new CustomEvent(
      "showOrHideFacebookBlockComponent",
      {
        bubbles: true,
        detail: {},
      }
    );
    window.dispatchEvent(showOrHideFacebookBlockEvent);
  };

  const updateShoppingSummary = (towns) => {
    const updateSummaryPageEvent = new CustomEvent("updateSummaryComponent", {
      bubbles: true,
      detail: { towns },
    });
    window.dispatchEvent(updateSummaryPageEvent);
  };

  const updateFacebookTown = (town, isSelected) => {
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithFacebook = selectedTowns.map((selectedTown) => {
      if (selectedTown?.id === town?.id) {
        return { ...selectedTown, fb_selected: isSelected };
      }
      return selectedTown;
    });

    cache.set(SELECTED_TOWNS, selectedTownsWithFacebook);
    const facebookTowns = selectedTownsWithFacebook
      .filter((town) => town.fb_selected)
      .map((town) => town.id);

    cache.set(FACEBOOK_TOWN_IDS, facebookTowns);
    updateShoppingSummary(selectedTownsWithFacebook);
  };

  const updateAdsFreeTowns = (town, isSelected) => {
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedAdsFreeTowns = selectedTowns.map((selectedTown) => {
      if (selectedTown?.id === town?.id) {
        return { ...selectedTown, ads_free_selected: isSelected };
      }
      return selectedTown;
    });

    cache.set(SELECTED_TOWNS, selectedAdsFreeTowns);
    const adsFreeTowns = selectedAdsFreeTowns
      .filter((town) => town.ads_free_selected)
      .map((town) => town.id);

    cache.set(ADS_FREE_TOWN_IDS, adsFreeTowns);
    updateShoppingSummary(selectedAdsFreeTowns);
  };

  const updateEnewsTowns = (town, isSelected) => {
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithEnews = selectedTowns.map((selectedTown) => {
      if (selectedTown?.id === town?.id) {
        return {
          ...selectedTown,
          enews_selected: isSelected,
        };
      }
      return selectedTown;
    });
    cache.set(SELECTED_TOWNS, selectedTownsWithEnews);
    const enewsTowns = selectedTownsWithEnews
      .filter((town) => town.enews_selected)
      .map((town) => town.id);
    cache.set(ENEWS_TOWN_IDS, enewsTowns);
    updateShoppingSummary(selectedTownsWithEnews);
  };

  const addSelectedTownDiy = (clickedTown) => {
    const cacheTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    cacheTowns.push(clickedTown);
    setSelectedTownsDiy(cacheTowns);
  };

  const handleSelectSingleTown = (town) => {
    cache.set(SELECTED_TOWNS, town ? [town] : []);
    setSelectedTownsDiy(cache.get(SELECTED_TOWNS));
  };

  const removeSelectedTownDiy = (clickedTown) => {
    const selectedTowns = getSelectedTowns();
    const foundItemToRemoveIndex = selectedTowns.findIndex(
      (town) => town.id === clickedTown.id
    );
    if (foundItemToRemoveIndex >= 0) {
      selectedTowns.splice(foundItemToRemoveIndex, 1);
      cache.set(SELECTED_TOWNS, selectedTowns);
      setSelectedTownsDiy(getSelectedTowns());
    }
    const facebookTowns = cache
      .get(SELECTED_TOWNS)
      .filter((town) => town.fb_selected)
      .map((town) => town.id);
    cache.set(FACEBOOK_TOWN_IDS, facebookTowns);
  };

  const addMultipleTownsDiy = (towns, isInitialLoad = false) => {
    const cacheTowns = isInitialLoad
      ? []
      : cloneWithoutReferences(selectedTownsDiy);
    for (let i = 0; i < towns.length; i++) {
      const foundItemToRemoveIndex = cacheTowns.findIndex(
        (cacheTown) => cacheTown.id === towns[i].id
      );
      if (foundItemToRemoveIndex === -1) {
        cacheTowns.push({ ...towns[i], selected: true });
      }
    }
    setSelectedTownsDiy(cacheTowns);
  };

  const removeMultipleTownsDiy = (towns) => {
    const cacheTowns = cloneWithoutReferences(selectedTownsDiy);
    for (let i = 0; i < towns.length; i++) {
      const foundItemToRemoveIndex = cacheTowns.findIndex(
        (cacheTown) => cacheTown.id === towns[i].id
      );
      cacheTowns.splice(foundItemToRemoveIndex, 1);
    }
    setSelectedTownsDiy(cacheTowns);
  };

  const addItemCountToCart = () => {
    const updateItemCount = new CustomEvent("updateItemCountComponent", {
      bubbles: true,
      detail: {},
    });
    window.dispatchEvent(updateItemCount);
  };

  // This runs first when clicking Facebook checkbox
  const checkFacebookOption = () => {
    const updateCheckFacebookOption = new CustomEvent(
      "updateFacebookOptionComponent",
      {
        bubbles: true,
        detail: {},
      }
    );
    const facebookCheckbox = cache.get(POST_ON_FACEBOOK) === 1;
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithFacebook = selectedTowns.map((selectedTown) => {
      return { ...selectedTown, fb_selected: facebookCheckbox };
    });

    cache.set(SELECTED_TOWNS, selectedTownsWithFacebook);
    const facebookTowns = selectedTownsWithFacebook
      .filter((town) => town.fb_selected)
      .map((town) => town.id);

    cache.set(FACEBOOK_TOWN_IDS, facebookTowns);
    updateShoppingSummary(selectedTownsWithFacebook);
    window.dispatchEvent(updateCheckFacebookOption);
  };

  const checkAdsFreeOption = () => {
    const updateAdsFreeOption = new CustomEvent(
      "updateAdsFreeOptionComponent",
      {
        bubbles: true,
        detail: {},
      }
    );
    const adsFreeCheckbox = cache.get(AD_FREE) === 1;
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithAdsFree = selectedTowns.map((selectedTown) => {
      return { ...selectedTown, ads_free_selected: adsFreeCheckbox };
    });

    cache.set(SELECTED_TOWNS, selectedTownsWithAdsFree);
    const adsFreeTowns = selectedTownsWithAdsFree
      .filter((town) => town.ads_free_selected)
      .map((town) => town.id);

    cache.set(ADS_FREE_TOWN_IDS, adsFreeTowns);
    updateShoppingSummary(selectedTownsWithAdsFree);
    window.dispatchEvent(updateAdsFreeOption);
  };

  const checkInludeInEnews = () => {
    const updateCheckEnewsOption = new CustomEvent(
      "updateEnewsOptionComponent",
      {
        bubbles: true,
        detail: {},
      }
    );
    const updateDatesEnewsOption = new CustomEvent("updateEnewsDates", {
      bubbles: true,
      detail: {},
    });
    const enewsCheckbox = cache.get(INCLUDE_IN_ENEWS) === 1;
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithEnews = selectedTowns.map((selectedTown) => {
      return { ...selectedTown, enews_selected: enewsCheckbox };
    });

    cache.set(SELECTED_TOWNS, selectedTownsWithEnews);
    const enewsTowns = selectedTownsWithEnews
      .filter((town) => town.enews_selected)
      .map((town) => town.id);

    cache.set(ENEWS_TOWN_IDS, enewsTowns);
    updateShoppingSummary(selectedTownsWithEnews);
    window.dispatchEvent(updateCheckEnewsOption);
    if (enewsCheckbox) {
      cache.set(ENEWS_DATES_ARRAY, selectedEnewsDatesForDatabase);
      window.dispatchEvent(updateDatesEnewsOption);
    }
  };

  const setAllTownsToSelected = () => {
    setSelectedTownsDiy([]);
  };
  removeTownListener(removeSelectedTownDiy);

  const handleAddEnewsDate = () => {
    const updateDatesEnewsOption = new CustomEvent("updateEnewsDates", {
      bubbles: true,
      detail: {},
    });
    const formattedDate = new Date(
      new Date().setDate(new Date().getDate() + 2)
    ).toLocaleDateString("en-US");

    setSelectedEnewsDatesForDatabase((prevDates) => [
      ...prevDates,
      { id: null, date: formattedDate },
    ]);

    cache.set(ENEWS_DATES_ARRAY, [
      ...selectedEnewsDatesForDatabase,
      { id: null, date: formattedDate },
    ]);
    window.dispatchEvent(updateDatesEnewsOption);
  };

  const handleRemoveEnewsDate = (index) => {
    if (selectedEnewsDatesForDatabase.length > 1) {
      const newDates = selectedEnewsDatesForDatabase.filter(
        (_, i) => i !== index
      );
      setSelectedEnewsDatesForDatabase(newDates);
      const updateDatesEnewsOption = new CustomEvent("updateEnewsDates", {
        bubbles: true,
        detail: {},
      });
      cache.set(ENEWS_DATES_ARRAY, newDates);
      window.dispatchEvent(updateDatesEnewsOption);
    }
  };

  const handleUpdateEnewsDate = (index, newDate, dateId) => {
    const updatedDatesforDatabase = [...selectedEnewsDatesForDatabase];
    updatedDatesforDatabase[index] = { id: dateId, date: newDate };

    setSelectedEnewsDatesForDatabase(updatedDatesforDatabase);

    const updateDatesEnewsOption = new CustomEvent("updateEnewsDates", {
      bubbles: true,
      detail: {},
    });
    cache.set(ENEWS_DATES_ARRAY, updatedDatesforDatabase);
    window.dispatchEvent(updateDatesEnewsOption);
  };

  const checkAdFreeOption = async () => {
    try {
      const response = await restClient.get(withConfig("AD_FREE_OPTION"));
      setAdFlag(response?.data?.ad_free);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAdFreeOption();
  }, []);

  useEffect(() => {
    const fetchOptionalPrices = async () => {
      try {
        const response = await restClient.get(
          withConfig("FETCH_OPTIONAL_PRICES"),
          {
            contribution_type: contributionType,
          }
        );
        if (response?.status == 200) {
          setOptionalPrices(response?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (contributionType) {
      fetchOptionalPrices();
    }
  }, [contributionType]);

  return (
    <DiyContext.Provider
      value={{
        showOrHideFacebookBlock,
        updateShoppingSummary,
        selectedTownsDiy,
        addSelectedTownDiy,
        removeSelectedTownDiy,
        addMultipleTownsDiy,
        removeMultipleTownsDiy,
        addItemCountToCart,
        checkFacebookOption,
        checkAdsFreeOption,
        checkInludeInEnews,
        updateAdsFreeTowns,
        checkInludeInEnews,
        updateFacebookTown,
        updateEnewsTowns,
        setAllTownsToSelected,
        handleSelectSingleTown,
        setTown,
        town,
        setFormType,
        formType,
        setContributionType,
        contributionType,
        adFlag,
        optionalPrices,
        handleAddEnewsDate,
        handleUpdateEnewsDate,
        handleRemoveEnewsDate,
        selectedEnewsDatesForDatabase,
        setSelectedEnewsDatesForDatabase,
      }}
    >
      {children}
    </DiyContext.Provider>
  );
};

DiyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDiy = () => {
  const diyContextValues = useContext(DiyContext);

  if (!diyContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the DiyContextProvider"
    );
  }

  return diyContextValues;
};

const withDiyContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <DiyContextProvider>
      <Component {...props} />
    </DiyContextProvider>
  );

  return ComponentWithProvider;
};

export { DiyContext, DiyContextProvider, useDiy, withDiyContextProvider };
